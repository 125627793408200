import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  CloseIconBlock,
  SignOutHandler,
  UrlLinks,
  useDeviceType,
  DeviceTypes,
  Navigate,
  approvalUrl,
  isGuest,
  memberHome,
  shouldDisplayGetHealthyMenu
} from 'common';
import { ContactUsLink } from '../header/header';
import { GlobalContext } from 'context';
import {DashboardLinks} from 'pages';
import {
  Button,
  UserIconBlack,
  Accordion,
  ModalOverlayHamburger,
  UserIconBlackFull,
  HamburgerIcon,
  Link
} from '@sentaraui/optimahealth_web';
/**
 * Hamburger menu props
 */
interface HamburgerMenuProps {
  showLogout: boolean;
}
/**
 * Shows logout
 * @param { showLogout }
 * @returns
 */

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ showLogout }) => {
  const { t } = useTranslation();
  const signOut = SignOutHandler();
  const deviceType = useDeviceType();
  const isDesktop = deviceType === DeviceTypes.DESKTOP;
  const shouldShowIcon = true;
  const { getFeature, proxyData, isDesignatedRepresentative, loginMID, proxyMemberId } =
    useContext(GlobalContext);
  const { benefitsUrl, paymentCalims, doctorsMedication, getHealthy, memberProfileUrl, guestBenefitsUrl, designatedRepresentativeUrl }: any =
    UrlLinks();
  const profileUrl=  window.location.pathname !== Navigate.profile;



  // Initialize navigation function
  const navigate = useNavigate();

  const navigateViewDesignated = () => {
    navigate(approvalUrl);
    setIsOpen(false);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [guestValue, setGuestValue] = useState<boolean>(false);

  // Using the useEffect hook to perform an operation after the component has been rendered
  useEffect(() => {
    // Immediately invoked async function within the useEffect hook
    (async () => {
      // Fetching the decrypted user details
      const isGuestUser = await isGuest();
      setGuestValue(isGuestUser);
    })();
  }, []);

  /**
   * Opens modal
   */
  function openModal() {
    setIsOpen(true);
  }
  /**
   * Closes modal
   */
  function closeModal() {
    setIsOpen(false);
  }

  const handleAccordionToggleItem = (index: number) => {
    return index;
  };

  return (
    <>
      {!isDesktop && (
        <div>
          <button
            className="btn-link border-0 bg-transparent text-decoration-none ms-3"
            type="button"
            aria-label="Toggle navigation"
            onClick={openModal}
            data-testid="hamburgerIconButton"
          >
            <HamburgerIcon />
          </button>
          <ModalOverlayHamburger
            isOpen={isOpen}
            onRequestClose={closeModal}
            data-testid="hamburgerClose"
            contentLabel={t('hamburger.label.overlayInfo')}
          >
            <div className="hamburger-menu-overlay">
              <div className="pt-4 p-3 pb-2">
                <button
                  type="button"
                  data-testid="hamburgerBtnClose"
                  className="btn-link border-0 bg-transparent d-inline-flex align-items-center text-decoration-none"
                  onClick={closeModal}
                >
                  <span className="pe-2">
                    <CloseIconBlock />
                  </span>
                  <span className="close-text pt-1">
                    {t('appointments.buttonLableTwoClose')}
                  </span>
                </button>
              </div>
              <hr className="border-w-2" />
              <div className="p-3">
                {showLogout && (
                  <div className="mb-4">
                    <Button
                      dataTestId={'submitButton'}
                      variant={
                        'secondary_btn w-100 d-flex gap-2 align-items-center justify-content-center'
                      }
                      onClick={signOut}
                    >   <>
                    <UserIconBlack /> {t('header.label.logout')}
                  </>
                  </Button>
                  </div>
                )}
                {proxyData.Result &&
                    proxyData.Result.length > 0 &&
                    !isDesignatedRepresentative &&
                    !guestValue && (
                  <div className="d-flex align-items-center mb-3">
                  <span className="me-1">
                    <UserIconBlackFull />
                  </span>
                      <button
                        className="bg-transparent border-0 redhat_medium fw-500 darkblue-link link-hover"
                        onClick={(e: { preventDefault: () => void }) => {
                          e.preventDefault();
                          navigateViewDesignated();
                        }}
                      >
                        {t('proxy.label.mydesignatedRepresentatives')}
                      </button>
                </div>
                  )}
                <div className="ps-1">
                  <div className="d-flex align-items-center flex-no-wrap">
                      <ContactUsLink
                        memberID={loginMID}
                        showIcon={shouldShowIcon}
                        onItemClick={() => setIsOpen(false)}
                      />
                  </div>
                </div>
              </div>
              <hr className="border-w-2" />

              {showLogout && (
                <div className="pb-4">
                  <div className="accordion-hamburgermenu">
                    <Accordion
                      onToggleItem={handleAccordionToggleItem}
                      initialActiveIndex={0}
                      id="hamburger_menu_mob"
                      items={[
                        getFeature?.MemberAccount &&
                        proxyMemberId !=='' && {
                          accIcon: <></>,
                          id: 'member_profile_mob',
                          content: (
                            <>
                              {profileUrl &&
                                memberProfileUrl.length > 0 && (
                                  <DashboardLinks
                                    data={memberProfileUrl}
                                    showContent={getFeature?.MemberAccount}
                                    closeModal={closeModal}
                                  />
                                )}
                            </>
                          ),
                          title: t('navigation.label.memberAccount'),
                        },
                        //Benefist coverage for for member
                        getFeature?.BenefitsCoverage && ((
                          !guestValue &&
                          !isDesignatedRepresentative) || proxyMemberId !=='') && {
                          content: (
                            <>
                            {profileUrl&&
                              benefitsUrl.length > 0 && (
                                <DashboardLinks
                                  data={benefitsUrl}
                                  showContent={getFeature?.BenefitsCoverage}
                                  closeModal={closeModal}
                                />
                              )}
                            </>
                          ),
                          title: t('navigation.label.benefitsCoverage'),
                          id: 'benefits_coverage_mob',
                        },
                        //To show only enroll and benefits link in side menu for guest user
                        guestValue &&
                        !isDesignatedRepresentative && proxyMemberId ==='' && {
                          id: 'benefit_coverage_guest_mob',
                          content: (
                            <>
                              {profileUrl &&
                                guestBenefitsUrl.length > 0 && (
                                  <DashboardLinks
                                    data={guestBenefitsUrl}
                                    showContent={getFeature?.BenefitsCoverage}
                                    closeModal={closeModal}
                                  />
                                )}
                            </>
                          ),
                          title: t('navigation.label.benefitsCoverage'),
                        },
                         //To show only forms and documents link in side menu
                        isDesignatedRepresentative && proxyMemberId ===''  && {
                          id: 'benefits_coverage_mob',
                          content: (
                            <>
                              {profileUrl &&
                                designatedRepresentativeUrl.length > 0 && (
                                  <DashboardLinks
                                    data={designatedRepresentativeUrl}
                                    showContent={getFeature?.BenefitsCoverage}
                                    closeModal={closeModal}
                                  />
                                )}
                            </>
                          ),
                          title: t('navigation.label.benefitsCoverage'),
                        },
                        //To show the PaymentsBillingAndClaims links in side menu
                        getFeature?.PaymentsBillingAndClaims && ((
                          !guestValue &&
                          !isDesignatedRepresentative) || proxyMemberId !=='') && {
                            content: (
                            <>
                              {profileUrl &&
                                paymentCalims.length > 0 && (
                                <DashboardLinks
                                  data={paymentCalims}
                                  closeModal={closeModal}
                                />
                              )}
                            </>
                          ),
                          title: t('navigation.label.paymentsBillingsClaims'),
                          id: 'payments_billing_mob',
                        },
                        //To show the docotor and Medication link in side menu
                        getFeature?.DoctorAndMedications && ((
                          !guestValue &&
                          !isDesignatedRepresentative) ||  proxyMemberId !=='') &&  {
                          content: (
                            <>
                              {profileUrl && <DashboardLinks data={doctorsMedication} closeModal={closeModal}/>}
                            </>
                          ),
                          title: t('navigation.label.doctorsMedication'),
                          id: 'doctor_medication_mob',
                        },
                        //To show the gethealthy link in side menu
                        shouldDisplayGetHealthyMenu(guestValue, isDesignatedRepresentative, proxyMemberId, getHealthy, getFeature?.GetHealthy) && {
                          content: (
                              <DashboardLinks
                                data={getHealthy}
                                closeModal={closeModal}
                              />
                          ),
                          title: t('navigation.label.getHealthy'),
                          id: 'get_healthy_mob',
                        },
                      ].filter((item) => item)}
                    />
                  </div>
                  {(getFeature?.BenefitsCoverage ||
                    getFeature?.PaymentsBillingAndClaims ||
                    getFeature?.DoctorAndMedications ||
                    getFeature?.GetHealthy) && <hr className="border-w-2" />}
                  <ul className="hamburger-nav-two">
                  <Link
                         to={Navigate.profile}
                        className="active"
                        data-testid="navLinkProfile"
                      >
                        {t('header.label.myOptimaAccount')}
                      </Link>
                    <li>
                    <Link
                        to={memberHome}
                        target="_blank"
                        data-testid="navLinkMembers"
                        rel="noreferrer"
                        onClick={() => setIsOpen(false)}
                      >
                        {t('header.label.memberHome')}
                      </Link>
                    </li>
                    <li>
                      {getFeature?.MyProfile && !guestValue && (
                        <Link
                        to={Navigate.profile}
                        data-testid="profileButton"
                      >
                        {t('header.label.profile')}
                      </Link>
                      )}
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </ModalOverlayHamburger>
        </div>
      )}
    </>
  );
};
export default HamburgerMenu;