import { useEffect } from 'react';
import { Route, Routes, useNavigate  } from 'react-router-dom';
import {
  MemberIdCard,
  Authorizations,
  EnrollInBenefits,
  PlanDocuments,
  CoveredEligibleMembers,
  MyPlan,
  TreatmentEstimateCosts,
  PharmacyClaims,
  MedicalClaims,
  Benefits,
  FormAndDocument,
  Dashboard,
  AuthorizationDetails,
  MedicalClaimsDetails,
  ViewTaxSummary,
  ContactUs,
  Profile,
  ProxyTabs,
  MemberProfile
} from 'pages';
import { RequireAuth, approvalUrl, signIn, Navigate, 
  claimsSummary, } from 'common';
const SideMenuRoutes = () => {
   //Navigate to medical claims summary when EOB URL is accessed
   const navigate = useNavigate();
   const getEOBURL = () => window.location.pathname?.toLowerCase().includes('home/claims-summary/');
   useEffect(() => {    
     const eobURL = getEOBURL();
     if(eobURL) {
       navigate(Navigate.medicalClaimsURL);  
     }
   }, [getEOBURL()]);

  return (
      <Routes>
        <Route path={signIn} element={<Dashboard />} />
        <Route
          path="*"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route
          path="/dashboard"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        />

        <Route
          path="/profile"
          element={
            <RequireAuth>
              <Profile />
            </RequireAuth>
          }
        />

        <Route
          path="/home/your-plan"
          element={
            <RequireAuth>
              <MyPlan />
            </RequireAuth>
          }
        />
        <Route
          path="/home/benefits"
          element={
            <RequireAuth>
              <Benefits />
            </RequireAuth>
          }
        />
        <Route
          path="/home/contact-us"
          element={
            <RequireAuth>
              <ContactUs />
            </RequireAuth>
          }
        />
        <Route
          path={approvalUrl}
          element={
            <RequireAuth>
              <ProxyTabs />
            </RequireAuth>
          }
        />
        <Route
          path="/home/plan-documents"
          element={
            <RequireAuth>
              <PlanDocuments />
            </RequireAuth>
          }
        />
        <Route
          path="/home/covered-eligible-members"
          element={
            <RequireAuth>
              <CoveredEligibleMembers />
            </RequireAuth>
          }
        />
        <Route
          path="/home/enroll-benefits"
          element={
            <RequireAuth>
              <EnrollInBenefits />
            </RequireAuth>
          }
        />

<Route
          path={Navigate.memberProfile}
          element={
            <RequireAuth>
              <MemberProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/home/forms-and-documents"
          element={
            <RequireAuth>
              <FormAndDocument />
            </RequireAuth>
          }
        />
        <Route
          path="/home/member-id-card"
          element={
            <RequireAuth>
              <MemberIdCard />
            </RequireAuth>
          }
        />
        <Route path="/home/authorizations" element={<Authorizations />} />

        <Route
          path="/home/view-tax-summary"
          element={
            <RequireAuth>
              <ViewTaxSummary />
            </RequireAuth>
          }
        />

        <Route
          path="/home/authorizations/authorizationDetails"
          element={
            <RequireAuth>
              <AuthorizationDetails />
            </RequireAuth>
          }
        />
        <Route
          path="/home/costs-calculate"
          element={
            <RequireAuth>
              <TreatmentEstimateCosts />
            </RequireAuth>
          }
        />
        <Route
          path="/home/pharmacy-claims"
          element={
            <RequireAuth>
              <PharmacyClaims />
            </RequireAuth>
          }
        />
        <Route
          path="/home/medical-claims"
          element={
            <RequireAuth>
              <MedicalClaims />
            </RequireAuth>
          }
        />
        <Route
          path="/home/behavioral-claims"
          element={
            <RequireAuth>
              <MedicalClaims />
            </RequireAuth>
          }
        />
        <Route
          path={claimsSummary}
          element={
            <RequireAuth>
              <MedicalClaimsDetails />
            </RequireAuth>
          }
        /> 
        {/* This route is for EOB Link */}
        <Route
          path={`${claimsSummary}${"/:claimToken/:claimType/:isEOB/:isToken/:memberSuffix"}`}
          element={
            <RequireAuth>
              <MedicalClaimsDetails />
            </RequireAuth>
          }
        />
        <Route
          path="/profile"
          element={
            <RequireAuth>
              <Profile />
            </RequireAuth>
          }
        />
      </Routes>
    
  );
};

export default SideMenuRoutes;
