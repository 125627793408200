import React, { useState, useContext, useEffect } from 'react';
import { t } from 'i18next';
import {
  Button,
  InputField,
  Label,
  Input,
  ModalOverlay,
  ErrorMessage,
  Checkbox,
  Paragraph,
  Headings,
  Collapsible,
  Loader,
  Link
} from '@sentaraui/optimahealth_web';
import { useForm } from 'react-hook-form';
import { SmsConsent, PhoneNotificationProps } from './interface';
import { ProfileHeaderContent } from 'templates';
import {
  formatePhoneNo,
  errorhandler,
  useDeviceType,
  DeviceTypes,
  NumberFormat,
} from 'common';
import {
  SMSTokenResponse,
  useSaveEmail,
  useSMSToken,
} from '@sentara/sentara-api-hooks-core';
import { GlobalContext } from '../../context';
import ConfirmIdentityModal from './ConfirmIdentityModal';

const PhoneNotifications = ({
  userPhone,
  smsConsent,
  setState,
  state,
  expand = true,
  onExpand,
  onCollapse,
}: PhoneNotificationProps) => {
  const {
    register,
    unregister,
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    reset,
    trigger,
    clearErrors,
    formState: { isValid, errors },
  } = useForm({});

  const [showPrimaryNumber, setShowPrimaryNumber] = useState(false);
  const [smsConsentDetails, setSmsConsentDetails] = useState({} as SmsConsent);
 const {getSMSToken, verifySMSToken} = useSMSToken();
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [isConfirmIdentityOpen, setIsConfirmIdentityOpen] =
    useState<boolean>(false);
  const [isConfirmIdentityCancel, setIsConfirmIdentityCancel] =
    useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [smsValue, setSMSValue] = useState<string>('');
  const [isScrolledToBottom, setIsScrolledToBottom] = useState<boolean>(false);
  const [inValidPhoneNumber, setInValidPhoneNumber] = useState<boolean>(false);
  const [inValidOPTError, setInValidOPTError] = useState<boolean>(false);

  const smsInputMaxLength = 6;

  const YesForReceiveTextMsg = (
    <>
      <strong>{t('account.label.buttonLableOne')}</strong>,{' '}
      {t('settings.label.wantToReceive')}
    </>
  );
  const NoForReceiveTextMsg = (
    <>
      <strong>{t('account.label.buttonLableTwo')}</strong>,{' '}
      {t('settings.label.notToReceive')}
    </>
  );

  useEffect(() => {
    if (smsConsent) {
      setSmsConsentDetails(smsConsent);
    }
  }, [smsConsent]);

  useEffect(() => {
    if (smsConsentDetails?.mobileNumber) {
      const phoneNo = smsConsentDetails?.mobileNumber;
      if (phoneNo === formatePhoneNo(userPhone[0]?.phoneNumber)) {
        setValue('phoneNoCheckBox', true);
      } else {
        setValue('phoneNoCheckBox', false);
      }
      setValue('phone', smsConsentDetails?.mobileNumber);
    }
  }, [smsConsentDetails]);

  // Function to handle the phone number format
  const handlePhoneNoChange = (event: string) => {
    const phoneNo = formatePhoneNo(event);
    if (phoneNo === formatePhoneNo(userPhone[0]?.phoneNumber)) {
      setValue('phoneNoCheckBox', phoneNo);
      setSMSValue(userPhone[0]?.phoneNumber)
    } else {
      setValue('phoneNoCheckBox', false);
      setSMSValue(event)
    }
    setValue('phone', phoneNo);
    getValues('phone');
    setSmsConsentDetails({
      ...smsConsentDetails,
      mobileNumber: phoneNo,
    });
  };

  const clearPhoneNo = () => {
    setValue('phone', '');
    setSmsConsentDetails({
      ...smsConsentDetails,
      mobileNumber: '',
    });
  };

  const cancelConstent = () => {
    reset();
    setShowPrimaryNumber(false);

    if (smsConsent?.termsAndCondition) {
      setIsScrolledToBottom(true);
      setValue('termsCondition', true);
    } else {
      setIsScrolledToBottom(false);
    }
    setSmsConsentDetails(smsConsent as SmsConsent);
  };

  const closePopUpConfirmModal = () => {
    setIsConfirmIdentityOpen(false);
    setIsConfirmIdentityCancel(true);
    setSmsVerified(true);
    cancelConstent();
  };

  const [isCheckedAlert, setIsCheckedAlert] = useState(false);
  const [smsVerified, setSmsVerified] = useState(false);

  const PRIMARY_BTN_BLOCK_MOB = 'primary_btn';

  function cancelPhoneAckAlert() {
    setIsCheckedAlert(false);
  }

  function confirmPhoneAckAlert() {
    setSmsConsentDetails({
      ...smsConsentDetails,
      optInConsent: true,
    });
    setIsCheckedAlert(false);
  }

  const [loading, setLoading] = useState<boolean>(false);
  const { saveEmail } = useSaveEmail();
  const { getFeature, loginMemberId } = useContext(GlobalContext);

  
  // Regular expression to allow only numbers
  const processPhoneNumber = (smsValue: string) => {
    return smsValue !== '' ? smsValue?.replace(NumberFormat, "") : smsConsent?.mobileNumber?.replace(NumberFormat, "");
  };

  // Handle response from API
  const handleResponse = async (response:SMSTokenResponse, smsAPI:boolean = false) => {
  setLoading(false);
  if (response?.errorCode) {      
    if(response.errorCode == '404'){
      setInValidOPTError(true);
    } else if(response.errorCode == '400'){
      setInValidPhoneNumber(true);
    } else{
      errorhandler(response.errorCode)
    }
  } else {
    if(smsAPI){
      setIsConfirmIdentityOpen(true);
    } else{
      updatePreferences();
    }
  }
};

//Calling SMS API to ge the smstoken
  const onSubmit = async () => {
    if (smsConsentDetails?.optInConsent) {
      setLoading(true);
      const smsPayload = {
        memberId: btoa(loginMemberId),        
        phoneNumber: '1'+ processPhoneNumber(smsValue)
      }
      const response  = await getSMSToken(smsPayload);
      handleResponse(response, true);       
      setSubmitClicked(false);
      setVerificationCode('');
    } else {
      if (smsConsent?.mobileNumber == null || (smsConsent?.mobileNumber === '' && smsConsentDetails.mobileNumber === '')) {
        setShowPrimaryNumber(false);
        return;
      }
       updatePreferences();
    }
  };  

  //Calling SMSVerification API to verify the smstoken
  const handleOtpSubmit = async () => {
    setLoading(true);
    const otpPayload = {
        memberId: btoa(loginMemberId),        
        phoneNumber: '1'+ processPhoneNumber(smsValue),
        verificationToken: verificationCode
      }
    const response = await verifySMSToken(otpPayload);
    handleResponse(response); 
  };

  // Calling the Update API to update the preferences
  const updatePreferences = async () => {
    setLoading(true);
    const smsConsent = {
      mobileNumber: smsConsentDetails.mobileNumber,
      optInConsent: smsConsentDetails.optInConsent,
      phoneType: smsConsentDetails.phoneType,
      termsAndCondition: smsConsentDetails.termsAndCondition,
    };
    const response = await saveEmail(
      undefined,
      getFeature?.AccountSettings,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      smsConsent
    );
    setLoading(false);
    if (response?.errorCode) {
      errorhandler(response?.errorCode);
    } else {
      setShowPrimaryNumber(false);
      setIsConfirmIdentityOpen(false);
      setSubmitClicked(true);
      if (smsConsentDetails.optInConsent) {
        setIsConfirmIdentityCancel(false);
        setSmsVerified(true);
      }
      setValue("phone", state?.smsConsent?.mobileNumber);
      setState({ ...state, smsConsent });
    }
  };

  // Handle the phone notification and consent
  const onPhoneNotificationsAndConsentHandle = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    await trigger(['termsCondition']);
    if (event.target.checked) {
      clearErrors('termsCondition');
      register('termsCondition', { required: false });      
    } else {
      register('termsCondition', { required: true });
      setError('termsCondition', { type: 'required' });
    }
  };

  // Handle the scroll event to enable the terms and condition checkbox
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const bottom =
      Math.round(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) ===
      e.currentTarget.clientHeight;
    if (bottom) {
      setIsScrolledToBottom(true);
    }
  };

  useEffect(() => {
    setSmsConsentDetails({
      optInConsent: smsConsent?.optInConsent ?? false,
      mobileNumber: smsConsent?.mobileNumber ?? '',
      phoneType: smsConsent?.phoneType ?? 'Mobile',
      termsAndCondition: smsConsent?.termsAndCondition ?? false,
    });
    if (smsConsent?.mobileNumber) {
      setValue('phone', smsConsent.mobileNumber);
    }

    if (smsConsent?.termsAndCondition) {
      setIsScrolledToBottom(true);
      setValue('termsCondition', true);
    }
  }, [smsConsent]);

  function closeSmsVerifiedPopup() {
    setSmsVerified(false);
    setShowPrimaryNumber(false);
  }

  const deviceType = useDeviceType();
  const isMobile = deviceType === DeviceTypes.MOBILE;

  return (
    <Collapsible
      title={t('phoneNotifications')}
      id={'phoneNotifications'}
      isMobile={isMobile}
      key={`${'phoneNotifications'}-${isMobile ? 'mobile' : 'desktop'}`}
      expand={expand}
      onExpand={onExpand}
      onCollapse={onCollapse}
      className="mb-2 pb-2"
    >
      <div className="set_my_preferences">
        <div className="border_wrapper">
          <div className="user_address_wrapper">
            {userPhone[0]?.phoneNumber && userPhone[0].phoneNumber.trim() !== '' ? 
              <>
                <div className="mb-3">
                  <ProfileHeaderContent
                    header={t('primaryPhoneNumber')}
                    contentOne={formatePhoneNo(userPhone[0]?.phoneNumber ?? '')}
                    dataTestIdOne="profileName"
                    contentClassNameOne={'_disabled mb-2'}
                  />
                </div>
                <hr />
              </>
            : null}
            <div className={`col-sm-12 col-12 ${!showPrimaryNumber && smsConsent?.optInConsent ? "col-md-12 col-lg-12" : "col-md-8 col-lg-8"}`}>
              <ProfileHeaderContent
                header={t('textNotifications')}
                className="mt-3"
                contentOne={t('textNotificationsContent')}
                disableButton={!state?.isMemberEnrolled}
                dataTestIdOne="textNotificationsContent"
                contentClassNameOne={'pt-2'}
                showButton={
                  !showPrimaryNumber && smsConsent?.optInConsent
                }
                onClick={() => {
                  setShowPrimaryNumber(true);
                }}
              />
              <div className="col-lg-auto col-md-auto col-sm-12 col-12 mt-0 mt-md-4 mt-lg-4">
                {!showPrimaryNumber && !smsConsent?.optInConsent && (
                  <Button
                    type="button"
                    dataTestId={'signupNotifications'}
                    children={t('signupNotifications')}
                    disabled={!state?.isMemberEnrolled}
                    variant={'secondary_btn btn_block_mob'}
                    onClick={() => setShowPrimaryNumber(true)}
                  />
                )}
              </div>
              {showPrimaryNumber ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                  {loading && <Loader />}
                  <div className="row">
                    <div className="d-flex flex-lg-row flex-md-row flex-column">
                      <div className="d-flex flex-column col-md-4 col-12 mb-md-0 mb-2">
                        <InputField
                          className={
                            errors.phone
                              ? 'form-input error-border'
                              : 'form-input'
                          }
                          type="text"
                          id="profileNumber"
                          data-testid="phoneInput"
                          placeholder={t(
                            'settings.label.PhoneNumberPlaceHolder'
                          )}
                          {...register('phone', {
                            required: smsConsentDetails?.optInConsent,
                            maxLength: 14,
                            minLength: 14,
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setInValidPhoneNumber(false);
                              handlePhoneNoChange(e.target.value);
                            },
                          })}
                        />
                        {errors.phone ? (
                          <div className="mt-1">
                            <ErrorMessage children={t(
                            'settings.errors.enterPhoneNumber'
                          )} />
                          </div>
                        ): null }

                        {inValidPhoneNumber ? (
                          <div className="mt-1">
                            <ErrorMessage children={t(
                            'settings.errors.phoneNumber'
                          )} />
                          </div>
                        ): null }



                      </div>
                      <li className="d-flex mt-1 mt-md-2 ms-md-4">
                        <Checkbox
                          id="use_primary_no"
                          className="form-check-input mt-0"
                          type="checkbox"
                          disabled={userPhone[0]?.phoneNumber ? false : true}
                          children={t('usePrimaryPhoneNumber')}
                          dataTestId="use_primary_no"
                          checked={watch('phoneNoCheckBox')}
                          {...register('phoneNoCheckBox', {
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              e.target.checked
                                ? handlePhoneNoChange(
                                    userPhone[0]?.phoneNumber ?? ''
                                  )
                                : clearPhoneNo();
                              setValue('phoneNoCheckBox', e.target.checked);
                              clearErrors('phone');
                              setInValidPhoneNumber(false);
                            },
                          })}
                        />
                      </li>
                    </div>
                  </div>
                  <div className="row">
                    <ul className="pref_choose_options d-flex mt-4 mb-2 flex-lg-row flex-md-row flex-column">
                      <li className="col-md-6 col-12">
                        <div className="d-flex ">
                          <Input
                            id="phoneNotificationYes"
                            className="form-check-input mt-0"
                            children={YesForReceiveTextMsg}
                            name="phoneNotification"
                            type="radio"
                            disabled={false}
                            dataTestId="phoneNotificationYes"
                            checked={smsConsentDetails?.optInConsent}
                            onChange={() => {
                              setSmsConsentDetails({
                                ...smsConsentDetails,
                                optInConsent: true,
                              });
                              setIsCheckedAlert(false);
                            }}
                          />
                          <Label
                            htmlFor="phoneNotificationYes"
                            className="form-check-label pad_left_12 check-label-pad"
                            children={YesForReceiveTextMsg}
                          />
                        </div>
                        <Paragraph
                          className="font_12_normal"
                          children={t('yesRadioContent')}
                        />
                      </li>
                      <li className="col-md-6 col-12">
                        <div className="d-flex">
                          <Input
                            id="phoneNotificationNo"
                            className="form-check-input mt-0"
                            children={NoForReceiveTextMsg}
                            name="phoneNotification"
                            type="radio"
                            disabled={false}
                            dataTestId="phoneNotificationNo"
                            checked={!smsConsentDetails?.optInConsent}
                            onChange={() => {
                              setSmsConsentDetails({
                                ...smsConsentDetails,
                                optInConsent: false,
                              });
                              setIsCheckedAlert(true);
                              register('phone', { required: false });  
                              unregister('termsCondition');
                            }}
                          />
                          <Label
                            htmlFor="phoneNotificationNo"
                            className="form-check-label pad_left_12 check-label-pad"
                            children={NoForReceiveTextMsg}
                          />
                        </div>
                      </li>
                    </ul>
                    {smsConsentDetails?.optInConsent ? (
                      <div>
                        <div className="pb-3">
                          <Label
                            dataTestId="phoneAcknowledgement"
                            className="mb-0 pb-2"
                            children={t('settings.label.phoneAcknowledgement')}
                          />

                          <div
                            data-testid="phoneNotificationMessage"
                            className="phone_notify_msg_wrapper notification_border"
                            onScroll={handleScroll}
                          >
                            <div className="d-flex flex-column">
                              <Paragraph className="mb-0">
                                {t(
                                  'settings.label.phoneAcknowledgementTextArea1'
                                )}
                              </Paragraph>
                              <Paragraph className="mb-0">
                                {t(
                                  'settings.label.phoneAcknowledgementTextArea2'
                                )}
                              </Paragraph>
                              <Paragraph className="mb-0">
                                {t(
                                  'settings.label.phoneAcknowledgementTextArea3'
                                )}
                                <span className="px-1 pe-0">
                                  <Link
                                    to={
                                      t('setMyPreferences.message.toNumber') ||
                                      ''
                                    }
                                  >
                                    {t(
                                      'setMyPreferences.message.phoneNotificationMessage4'
                                    ) || ''}
                                  </Link>
                                  .
                                </span>
                                <span>
                                  {' '}
                                  {t(
                                    'settings.label.phoneAcknowledgementTextArea4'
                                  )}
                                </span>
                                <span>
                                  <Link
                                    to={
                                      t('setMyPreferences.message.toNumber') ||
                                      ''
                                    }
                                  >
                                    {' '}
                                    {t(
                                      'setMyPreferences.message.phoneNotificationMessage4'
                                    ) || ''}
                                  </Link>
                                  .
                                </span>
                              </Paragraph>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex pb-2">
                          <div className="pos-rel">
                            <Checkbox
                              id="phoneNotificationsAndConsentCheck"
                              disabled={!isScrolledToBottom}
                              className={
                                errors.termsCondition
                                  ? 'form-check-input mt-0 error-input-bdr'
                                  : 'form-check-input mt-0'
                              }
                              children={t(
                                'settings.label.PhoneNotificationAccept'
                              )}
                              type="checkbox"
                              checked={watch('termsCondition')}
                              dataTestId="phoneNotificationsAndConsentCheck"
                              {...register('termsCondition', {
                                required: true,
                                onChange: (
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setValue('termsCondition', e.target.checked);
                                  onPhoneNotificationsAndConsentHandle(e);
                                },
                              })}
                            />
                            {errors.termsCondition && (
                              <ErrorMessage
                                className="mb-0"
                                dataTestId={'phoneConsent'}
                                children={t(
                                  'setMyPreferences.error.phoneConsent'
                                )}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <hr />
                
                  <div className="pt-2 button_container">
                    <Button
                      dataTestId={'saveButton'}
                      children={t('save')}
                      variant={
                        isValid
                          ? 'primary_btn btn_block_mob'
                          : 'primary_btn btn_block_mob disabled_btn'
                      }
                      type="submit"
                    />
                    <Button
                      type="button"
                      dataTestId={'cancelButton'}
                      children={t('activation.label.cancel')}
                      variant={'secondary_btn btn_block_mob'}
                      onClick={() => {
                        cancelConstent();
                      }}
                    />
                  </div>
                </form>
              ) : null}
            </div>
          </div>
        </div>
        {isCheckedAlert && (
          <ModalOverlay
            isOpen={isCheckedAlert}
            onClose={cancelPhoneAckAlert}
            overlayInfo="Modal information"
          >
            <div data-testid="phoneAcknowledgementPopUp">
              <Headings
                level={2}
                text={t('settings.label.phoneAcknowledgementPopUpTitle')}
              />
              {/* Description paragraphs */}
              <p className="mb-4">
                {t('settings.label.phoneAcknowledgementPopUpContent')}
              </p>

              <div className="d-flex flex-lg-row flex-md-row flex-column gap-lg-3 gap-md-3 gap-4 mb-2">
                <Button
                  dataTestId={'imSureButton'}
                  children={t('settings.label.phoneAcknowledgementPopUpImSure')}
                  variant={PRIMARY_BTN_BLOCK_MOB}
                  type="submit"
                  onClick={() => {
                    cancelPhoneAckAlert();
                  }}
                />
                <Button
                  dataTestId={'signupButton'}
                  children={t('settings.label.phoneAcknowledgementPopUpSignup')}
                  variant={'secondary_btn'}
                  onClick={() => {
                    confirmPhoneAckAlert();
                  }}
                />
              </div>
            </div>
          </ModalOverlay>
        )}
        {isConfirmIdentityOpen  ? 
        <ConfirmIdentityModal
          closePopUpConfirmModal={closePopUpConfirmModal}
          isConfirmIdentityOpen={isConfirmIdentityOpen}
          getValues={getValues}
          verificationCode={verificationCode}
          setVerificationCode={setVerificationCode}
          submitClicked={submitClicked}
          inValidOPTError={inValidOPTError}
          setInValidOPTError={setInValidOPTError}
          setSubmitClicked={setSubmitClicked}
          onSubmit={onSubmit}
          handleOtpSubmit={handleOtpSubmit}
          t={t}
          smsInputMaxLength={smsInputMaxLength}
        />
        :
        null}
       
        {smsVerified && (
          <ModalOverlay
            isOpen={smsVerified}
            onClose={closeSmsVerifiedPopup}
            overlayInfo="Modal information"
          >
            <div data-testid="smsVerifiedPopUp">
              <Paragraph className="mb-4 pb-2">
                {!isConfirmIdentityCancel
                  ? t('settings.label.optedSuccess')
                  : t('settings.label.optedCancel')}
              </Paragraph>
              <div className="d-flex flex-lg-row flex-md-row flex-column gap-lg-3 gap-md-3 gap-4 mb-2">
                <Button
                  dataTestId={'okButton'}
                  children={t('settings.label.ok')}
                  variant={'primary_btn btn_block_mob'}
                  type="submit"
                  onClick={closeSmsVerifiedPopup}
                />
              </div>
            </div>
          </ModalOverlay>
        )}
      </div>
    </Collapsible>
  );
};

export default PhoneNotifications;
