import React, { useContext, useState, useEffect } from 'react';
import { t } from 'i18next';
import {
  Label,
  Input,
  Paragraph,
  ModalOverlay,
  Button,
  Headings,
  Collapsible,
  Link
} from '@sentaraui/optimahealth_web';

import { preferencesDetails, errorhandler, useDeviceType, DeviceTypes } from 'common';
import { useSaveEmail } from '@sentara/sentara-api-hooks-core';
import { GlobalContext } from 'context';
import {
  ProfileInformations
} from 'templates';

const LanguagePreference = ({
  languagePreference,
  expand = true,
  onExpand,
  onCollapse,
  state,
  setState
}: {
  languagePreference: string;
  expand?: boolean;
  onExpand?: () => void;
  onCollapse?: () => void;
  state: ProfileInformations,
  setState: React.Dispatch<React.SetStateAction<ProfileInformations>>
}) => {
  const { getFeature } = useContext(GlobalContext);
  const { saveEmail } = useSaveEmail();
  const [currentLangPref, setCurrentLangPref] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setCurrentLangPref(languagePreference===""?preferencesDetails.English:languagePreference);
  }, [languagePreference]);

  function closeModal() {
    setIsOpen(false);
    // to reverse the radio button selection
    if (currentLangPref === preferencesDetails.English) {
      setCurrentLangPref(preferencesDetails.Spanish);
    } else {
      setCurrentLangPref(preferencesDetails.English);
    }
  }

  const onChangeLanguage = async () => {
    setIsOpen(false);
    setLoader(true);
    const response = await saveEmail(
      undefined,
      getFeature?.AccountSettings,
      undefined,
      undefined,
      undefined,
      undefined,
      currentLangPref,
      undefined
    );
    if (response?.errorCode) {
      errorhandler(response?.errorCode);
    } else {
      setState({
        ...state,
        languagePreference: currentLangPref,
      });
      setLoader(false);
    }
  };
  const langPrefDescription = (
    <>
      {t('setMyPreferences.description.langPrefDescSpanish')}{' '}
      <br aria-hidden="true" />
      {t('setMyPreferences.description.textMsgSpanish')}{' '}
      <Link
        className="primary-color"
        to={'tel:18556876260'}
        rel="noopener noreferrer"
      >
        {' '}
        {t('setMyPreferences.description.langPrefTelephone')}{' '}
      </Link>
      {t('setMyPreferences.description.TTY711')}
    </>
  );
  const deviceType = useDeviceType();
  const isMobile = deviceType === DeviceTypes.MOBILE;

  return (
    <Collapsible
      title={t('settings.label.languagePreference')}
      id={'emailPaperless'}
      isMobile={isMobile}
      key={`${'emailPaperless'}-${isMobile ? 'mobile' : 'desktop'}`}
      expand={expand}
      isLoading={loader}
      onExpand={onExpand}
      onCollapse={onCollapse}
      className="mb-2 pb-2"
    >
      <div className="set_my_preferences">
      <div className="border_wrapper">
        <div className="user_address_wrapper">
          <div className="d-flex flex-column gap-2 pt-2">
            <Paragraph
              className={'mb-2'}
              children={t('setMyPreferences.description.langPrefDesc')}
            />
            <Paragraph children={langPrefDescription} />

            <div className="d-flex gap-5 pt-3 pb-3 pref_choose_options lang_choose">
              <div className="d-flex">
                <Input
                  id="langEnglish"
                  className="form-check-input mt-0"
                  children={`English`}
                  name="languagePref"
                  type="radio"
                  disabled={!state.isMemberEnrolled}
                  dataTestId="langEnglish"
                  value={preferencesDetails.English}
                  checked={currentLangPref === preferencesDetails.English}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setCurrentLangPref(e.target.value);
                  }}
                />
                <Label
                  htmlFor="langEnglish"
                  className="form-check-label pad_left_12 check-label-pad"
                  children={t('languageSwitch.labels.english')}
                />
              </div>
              <div className="d-flex">
                <Input
                  id="langSpanish"
                  className="form-check-input mt-0"
                  children={`Spanish`}
                  name="languagePref"
                  type="radio"
                  disabled={!state.isMemberEnrolled}
                  dataTestId="langSpanish"
                  value={preferencesDetails.Spanish}
                  checked={currentLangPref === preferencesDetails.Spanish}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setCurrentLangPref(e.target.value);
                  }}
                />
                <Label
                  htmlFor="langSpanish"
                  className="form-check-label pad_left_12 check-label-pad"
                  children={t('languageSwitch.labels.spanish')}
                />
              </div>
            </div>
            <div className="lang_pref_btm pt-1">
              <small className="redhat_medium">
                {t('setMyPreferences.description.langPrefDisclaimer')}
                <strong>
                  <Link
                    to={'tel:18556876260'}
                    rel="noopener noreferrer"
                  >
                    {' '}
                    {t('setMyPreferences.description.langPrefTelephone')}
                  </Link>
               
                </strong>
                {t('dot')}
              </small>
            </div>
            {languagePreference!== currentLangPref && (
            <>
              <hr />
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="pt-2 button_container">
                  <Button
                    dataTestId="submitButton"
                    variant="primary_btn btn_block_mob"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    {t('settings.label.save')}
                  </Button>
                  <Button
                    dataTestId="cancelButton"
                    children={t('settings.label.cancel')}
                    variant={'secondary_btn btn_block_mob'}
                    onClick={() => {
                      // to reverse the radio button selection
                      if (currentLangPref === preferencesDetails.English) {
                        setCurrentLangPref(preferencesDetails.Spanish);
                      } else {
                        setCurrentLangPref(preferencesDetails.English);
                      }
                    }} 
                  />
              </div>
            </div>
            </>
            )}
          </div>
        </div>
      </div>
      {isOpen && (
        <ModalOverlay
          isOpen
          onClose={closeModal}
          className="modal_overlay"
          overlayInfo="Modal information"
        >
          <div data-testid="memberIdModal">
            <Headings
              className="redhat_regular font-twenty fw-700 mb-3 me-4"
              level={3}
              dataTestId="loggedMessage"
              text={t('settings.label.confirmLangPref')}
            />
            <Paragraph
              className="mb-0"
              data-testId="language-pref-description"
              children={t('settings.label.confirmLangPrefMsg')}
            />
            <div className="d-flex flex-column flex-lg-row flex-md-row  align-items-center  mt-3 gap-3">
              <Button
                dataTestId={'passwordSubmit'}
                children={t('settings.label.Yes')}
                variant={'primary_btn btn_block_mob'}
                onClick={() => {
                  onChangeLanguage();
                }}
              />
              <Button
                dataTestId={'passwordCancel'}
                children={t('settings.label.Cancel')}
                variant={'secondary_btn btn_block_mob'}
                onClick={closeModal}
              />
            </div>
          </div>
        </ModalOverlay>
      )}
      </div>
    </Collapsible>
  );
};

export default LanguagePreference;
