import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { usePlanDocument, useMyPlanEOC } from '@sentara/sentara-api-hooks-core';
import { PlanDocumentWidget } from 'templates';
import { Headings, DocumentLink, Loader, Paragraph, FileIcon } from '@sentaraui/optimahealth_web';
import { errorhandler, PDFConverter } from 'common';
import { GlobalContext } from '../../context';

const PlanDocuments = () => {
  // Initialize translation
  const { t } = useTranslation();

  // Fetch plan document types from API
  const { PlanDocumentType } = usePlanDocument();
  const { MyPlanEOCType } = useMyPlanEOC();
  const { getFeature } = useContext(GlobalContext);

  // State variables for different document types
  const [summaryBenefits, setSummaryBenefits] = useState(false);
  const [evidenceOfCoverage, setEvidenceOfCoverage] = useState(false);
  const [uniformSbc, setUniformSbc] = useState(false);
  const [acaTaxForm, setACATaxForm] = useState(false);
  const [benefitsSummary, setBenefitsSummary] = useState(false);
  const [coverageAmendment, setCoverageAmendment] = useState(false);
  const [memberGuide, setMemberGuide] = useState(false);
  const [isPlanDoc, setIsPlanDoc] = useState(false);
  const [documentTypes, setDocumentTypes] = useState<ResponseType>({} as ResponseType);

  // Loading and error handling states
  const [loader, setLoader] = useState(false);
  const [showError, setError] = useState(false);

  interface ResponseType {
    acaTaxForm: boolean;
    benefitsSummary: boolean;
    coverageAmendment: boolean;
    evidenceOfCoverage: boolean;
    summaryOfBenefits: boolean;
    uniformSbc: boolean;
    memberGuide: boolean;
    providerTerminationLetter: boolean;
  }
  // Fetch plan document types and handle responses
  useEffect(() => {
    (async () => {
        setLoader(true);
        setError(false);
        const res = await PlanDocumentType({});
        if (res?.errorCode !== undefined) {
          setLoader(false);
          errorhandler(res?.errorCode);
        } else {
          const apiResponse = res?.data as ResponseType;
          setDocumentTypes(apiResponse);
          const handleResponse = (response: ResponseType) => {
            // Function to set state if value is true
            const setIfTrue = (
              stateFn: (value: React.SetStateAction<boolean>) => void,
              value: boolean
            ) => {
              if (value === true) {
                stateFn(true);
              }
            };

            // Update state based on API response
            setIfTrue(setSummaryBenefits, response?.summaryOfBenefits);
            setIfTrue(setEvidenceOfCoverage, response?.evidenceOfCoverage);
            setIfTrue(setUniformSbc, response?.uniformSbc);
            setIfTrue(setACATaxForm, response?.acaTaxForm);
            setIfTrue(setBenefitsSummary, response?.benefitsSummary);
            setIfTrue(setCoverageAmendment, response?.coverageAmendment);
            setMemberGuide(response?.memberGuide)
          };

          handleResponse(apiResponse);
          setLoader(false);
        }
    })();
  }, []);

  useEffect(() => {
    // To show Error message
    if(Object.keys(documentTypes).length) {
      const allFalse = Object.values(documentTypes).every(value => value === false);
      const onlyMemberGuideTrue = Object.entries(documentTypes).every(([key, value]) => key === 'memberGuide' ? value === true : value === false);
      if (allFalse || (onlyMemberGuideTrue && !getFeature?.MemberGuide) ) {
        setError(true);
      }
    }
  }, [documentTypes, getFeature?.MemberGuide]);

  // Array of objects representing document types and their settings

  const planData = [
    {
      componentShow: evidenceOfCoverage,
      itemClickValue: 'eoc',
      iconDataTestId: 'coverage_certificate_image',
      spanDataTestId: 'coverage_certificate',
      tVal: 'planDocuments.label.groupInformation',
    },
    {
      componentShow: acaTaxForm,
      itemClickValue: 'aca',
      iconDataTestId: 'aca_tax_form_image',
      spanDataTestId: 'aca_tax_form',
      tVal: 'planDocuments.label.taxDocument',
    },
    {
      componentShow: coverageAmendment,
      itemClickValue: 'CA',
      iconDataTestId: 'aca_ca_form_image',
      spanDataTestId: 'aca_tax_ca_form',
      tVal: 'planDocuments.label.coverageAmendment',
    },
    {
      componentShow: uniformSbc,
      itemClickValue: 'summaryofbenefitscoverage',
      iconDataTestId: 'coverage_certificate_imag',
      spanDataTestId: 'uniform_summary',
      tVal: 'planDocuments.label.detailedCoverageDocuments',
    },
    {
      componentShow: benefitsSummary,
      itemClickValue: 'benefitsummary',
      iconDataTestId: 'coverage_certificate_image',
      spanDataTestId: 'benefits_summary',
      tVal: 'planDocuments.label.benefitsSummary',
    },
    {
      componentShow: summaryBenefits,
      itemClickValue: 'summaryofbenefits',
      iconDataTestId: 'coverage_summary_image',
      spanDataTestId: 'get_benefits_summary',
      tVal: 'planDocuments.label.coverageSummary',
    },
  ];

 useEffect(() => {
  setIsPlanDoc(Object.values(planData).some((value) => value.componentShow === true));
}, [planData]);

 
  // Function to handle summary of benefits item click
  const handleItemClick = async (item: string) => {
    setLoader(true);
    const data = await   MyPlanEOCType('memberguide');
    setLoader(false);
    if (data?.errorCode !== undefined) {
      errorhandler(data?.errorCode);
    } else {
      PDFConverter(data?.data, true, item);
    }
    setLoader(false);
  };

  return (
    <div className="plandocuments_provider">
      {loader && <Loader />}
      <div className="heading-one-semibold mb-4">
        {/* Display main heading */}
        <Headings
          dataTestId="plan_documents"
          level={1}
          text={t('navigation.label.planDocuments')}
        />
      </div>
      <div>
        {/* Display error message if showError is true */}
        {showError && (
          <Paragraph data-testid="planDocumentsError">{t('planDocuments.label.error')} </Paragraph>
        )}
        {/* To display the Member Guide Section */}
        <div className="grid-cont plan-grid-cont d-flex mb-1">
          <div className="left-border-white" aria-hidden="true"></div>
          {(memberGuide && getFeature?.MemberGuide) && (
             <div className="pdf_wrapper flex-column d-flex mb-3">
            <Headings
                  dataTestId="welcome_guide_title"
                  className="mb-4"
                  level={2}
                  text={t('planDocuments.label.memberGuide')}
                />
             <Paragraph data-testid="memberGuideMessage">{t('planDocuments.message.memberGuideMessage')}</Paragraph>
             <DocumentLink onItemClick={() => handleItemClick(t('planDocuments.label.MG'))} id={t('planDocuments.label.MG')} FileIcon={FileIcon}  pdfText={''}  dataTestId={'welcome_guide_form'} buttonValue={t('planDocuments.label.memberGuide')}/>
           </div>
          )}
         
             {/* TO display the Plan document section*/}
            {(memberGuide && isPlanDoc && getFeature?.MemberGuide)  ? (
              <>
                <div className="member_wrapper_border"></div>
                <Headings
                  dataTestId="planDocuments"
                  className="mb-4"
                  level={2}
                  text={t('navigation.label.planDocuments')}
                />
              </>
              ) : null}
              
              {planData.map(
                (item) =>
                  item.componentShow && (
                    <PlanDocumentWidget
                      itemClickValue={item.itemClickValue}
                      iconDataTestId={item.iconDataTestId}
                      spanDataTestId={item.spanDataTestId}
                      tVal={item.tVal}
                    />
                  )
              )}
        </div>
      </div>
    </div>
  );
};

export default PlanDocuments;
