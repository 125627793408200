import React, { useState, useContext, useEffect } from 'react';
import { Link, To } from 'react-router-dom';
import { useMyPlanSOB, useMySSOUrl, DocumentType } from '@sentara/sentara-api-hooks-core';
import {
  HCSEmployeeHealthWellnessURL,
  useDeviceType,
  DeviceTypes,
  errorhandler,
  MyPlanTabs,
  myPlanUrl
} from 'common';
import { GlobalContext } from 'context';
import { saveAs } from 'file-saver';
import { t } from 'i18next';
import { yourPlan } from '../../common/utils/constants/ConstantURL';
import { Loader } from '@sentaraui/optimahealth_web';


const DashboardLinks: React.FC<any> = ({ data, closeModal }) => {
  const { getFeature, isMemberTabDefault, setIsDesignatedTabShow } = useContext(GlobalContext);
  const { MyPlanSOBType } = useMyPlanSOB();
  const { SSOUrlType } = useMySSOUrl();
  const [loader, setLoader] = useState(false);
  const deviceType = useDeviceType();
  const isDesktop = deviceType === DeviceTypes.DESKTOP;
  const handleLinkClick = () => {
    closeModal();
  };
  const getAPIUrl = async (type: string) => {
    setLoader(true);
    const res = await SSOUrlType(type);
    if (res?.data?.ssoUrl) {
      openUrl(res?.data?.ssoUrl);
    } else if (res?.errorCode) {
      errorhandler(res?.errorCode);
    }
    setLoader(false);
  };

  

  // Function to handle Visio Care API Call
  const VisionCareSSOUrl = async (viewCarePlan: string) => {
    const data = await MyPlanSOBType(DocumentType.VISION_CARE);
    if (data?.errorCode !== undefined) {
      errorhandler(data?.errorCode);
      setLoader(false);
    } else {
      pdfConverter(data, viewCarePlan);
    }
  };

  // Function to convert data to PDF and trigger download
  const pdfConverter = (data: any, fileName: string) => {
    let blob;
    const filename = fileName;
    // converting the base64 resposne to pdf
    blob = new Blob([data], { type: 'application/pdf' });
    saveAs(blob, filename);
    setLoader(false);
  };

  const openUrl = (url: string | undefined) => {
    setLoader(false);
    window.open(url, '_blank');
  };
  const apiTypeMapping: Record<string, string | (() => void)> = {
    DentaQuest: 'DentaQuest',
    VisionCare: 'VisionCareSSOUrl',
    HRA: 'ChoiceStrategiesSSOUrl',
    HSA: 'HealthEquitySSOUrl',
    ManageRenewPolicy: 'SeibelSSOUrl',
    calculateTreatmentCost: 'CalculateTreatmentCostSSOUrl',
    WellsFargo: 'WellsFargo',
    FindADoctor: 'HelathSparqOneSSOUrl',
    ChangePrimaryCare: 'HelathSparqOneSSOUrl',
    PharmacyResources: getFeature?.PharmacyBenefitsManager
      ? 'EsiRx?source=orderrx'
      : 'OptumRx',
    VirtualVisit: 'MDLiveSSOUrl',
    HCSEmployeeHealthWellnessCtr: () => openUrl(HCSEmployeeHealthWellnessURL),
    TravelAssistance: 'AssistAmerica',
    Formulary: getFeature?.PharmacyBenefitsManager
      ? 'EsiRx?source=orderrx'
      : 'OptumRx',
    PersonalHealthRecord: 'WellnessToolSSOUrl?source=phr',
    PersonalHealthAssessment: 'WellnessToolSSOUrl?source=pha',
    WellnessTools: 'WellnessToolSSOUrl?source=wellness',
    ViewCarePlan: 'JivaSSOUrl',
  };
  const onItemClick = (e: any) => {
    const item = e;
    if (item) {
      const apiType = apiTypeMapping[item];
      if (typeof apiType === 'string') {
        if (item === t('navigation.case.viewCarePlan')) {
          if (getFeature?.JivaCarePlan) {
            getAPIUrl(apiType);
          } else {
            VisionCareSSOUrl(t('navigation.case.viewCarePlan'));
            setLoader(true);
          }
        } else {
          getAPIUrl(apiType);
        }
        if (item === t('navigation.case.calculateTreatmentCost')) {
          setLoader(true);
        }
      } else if (typeof apiType === 'function') {
        apiType();
      } else {
        setLoader(false);
      }
    }
  };
  const [listClassName, setListClassName] = useState('');
  useEffect(() => {
    if(window.location.pathname === yourPlan) {
      let defaultURLLink = myPlanUrl + ( isMemberTabDefault ? MyPlanTabs.memberDetailID: MyPlanTabs.overViewID);
      setListClassName(
        defaultURLLink
      );
    }
    else {
      setListClassName(
        window.location.pathname
      );
    } 
  }, [window.location.pathname,window.location.search]);
  
  return (
      <ul className="list-group link-list">
        {loader && <Loader />}
        {data.map(
          (item: {
            id: React.Key | null | undefined;
            to: To;
            onclick: any;
            toggle: boolean;
            title: string;
          }) => (
            <React.Fragment key={item.id}>
              {item.toggle === true && (
                <li className="list-group-item list-group-item-action">
                  <Link
                    className={`${
                      listClassName === item.to ? 'active-link-list' : ''
                    }`}
                    to={item.to}
                    data-testid={`${
                      item.title?.charAt(0).toLowerCase() + item.title.slice(1)
                    }Menu`.replace(/\s/g, '')}
                    onClick={() => {
                      setIsDesignatedTabShow(false);
                      item.onclick && onItemClick(item.onclick);
                      !isDesktop && handleLinkClick();
                    }}
                  >
                    {item.title}
                  </Link>
                </li>
              )}
            </React.Fragment>
          )
        )}
      </ul>
  );
};
export default DashboardLinks;
