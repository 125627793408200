import React, { useState } from 'react';
import { t } from 'i18next';
import { Button, ModalOverlay, Headings, Paragraph, Link } from '@sentaraui/optimahealth_web';
import { useMyDelete } from '@sentara/sentara-api-hooks-core';
import {DeleteAccountPopup} from 'templates';
import { CheckIconSmall, signIn,footerContactUs, errorhandler} from 'common';

const DeleteProfile = () => {
  const { MyDeleteType } = useMyDelete();
  const LINK_BTN_BLOCK_MOB = 'btn-links darkblue-color ps-0 mb-2';
  const PRIMARY_BTN_BLOCK_MOB = 'primary_btn';
  const [isSuccessPopup, setIsSuccessPopup] = useState<boolean>(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isShowContent, setIsShowContent] = useState<boolean>(false);

  // Function to handle account deletion
  const deleteAccount = async () => {
    setIsShowContent(false);
    setIsConfirmOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
  }

  // Function to open the delete account modal
  const submitDelete = (e: any) => {
    setIsOpen(true);
    setIsShowContent(true);
    setIsConfirmOpen(false);
  };

  function closePopUpModal() {
    setIsSuccessPopup(false);
  }

  const confirmDeleteAccount = async () => {
    const response = await MyDeleteType();
    if (response.errorCode) {
      errorhandler(response?.errorCode);
    }
    else {
      setIsConfirmOpen(false);
      localStorage.clear();
      window.location.href = signIn;
    }
  };

  const confirmationDeleteAccountInfo = (
    <>
      {t('settings.label.confirmationDeleteAccountInfo')}
      <Link  className="gold-link"  data-testid="customerSupportLink" to={footerContactUs}> {t('settings.label.customerSupportLink')}</Link>
    </>
  );


  return (
    <>
      {/* Delete account start */}
      <div className="set_my_preferences">
        <div className="pb-2">
          <div className="border border-clr p-4 mb-4 delete_account_border">
            <div className="heading_redhat_24 mb-4">
              <Headings
                className="redhat_bold font-twentyfour fw-700 primary-color mb-4"
                level={4}
                text={t('settings.label.deleteAccount')}
                data-testid="profileDeletAccountHeading"
              />
            </div>
            {/* Render confirmation message and button to delete account */}
            <Paragraph
              className="pb-3 mb-2"
              data-testid="confirmationDeleteAccountInfo"
             >
            {confirmationDeleteAccountInfo}
             </Paragraph>
            <Button
              dataTestId={'deleteAccountButton'}
              variant={LINK_BTN_BLOCK_MOB}
              onClick={(e: any) => {
                e.preventDefault();
                submitDelete(e);
              }}
            >
            {t('settings.label.continueToDeleteAccount')}
            </Button>
          </div>
        </div>
      </div>
      {/* Render the DeleteAccountPopup component */}
      <DeleteAccountPopup
        isOpen={isOpen}
        closeModal={closeModal}
        isShowContent={isShowContent}
        variant={PRIMARY_BTN_BLOCK_MOB}
        deleteAccount={deleteAccount}
        isConfirmOpen={isConfirmOpen}
        confirmDeleteAccount={confirmDeleteAccount}
      />
      {isSuccessPopup && (
        <ModalOverlay
          isOpen={isSuccessPopup}
          onClose={closePopUpModal}
          overlayInfo="Modal information"
        >
          <div data-testid="memberIdModal">
            <div className="d-flex align-items-center justify-content-center mb-3">
              <CheckIconSmall />
            </div>
            <h5 data-testid="loggedMessage" className="label_logged_message">
              {t('settings.label.success')}{' '}
            </h5>
          </div>
        </ModalOverlay>
      )}
      {/* Delete account end */}
    </>
  );
};

export default DeleteProfile;
