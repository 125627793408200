import { t } from 'i18next';
import { useState, useEffect, useContext, useMemo } from 'react';
import {
  useGetAddress,
  useMemberUpdate,
  useSaveSecurityQuestions,
} from '@sentara/sentara-api-hooks-core';
import { GlobalContext } from 'context';
import {
  Label,
  SelectDropdown,
  InputField,
  Button,
  ModalOverlay,
  ErrorMessage,
  Headings,
  Loader,
} from '@sentaraui/optimahealth_web';
import { getMaxLength, errorhandler } from 'common';
import { useForm } from 'react-hook-form';
import { HomeAddressDetailsProps } from './interface';

const HomeAddressDetails = ({
  street,
  zipCode,
  city,
  state,
  closeComponent,
  setUserAddress,
}: HomeAddressDetailsProps) => {
  const [pinCode, setPincode] = useState(zipCode);
  const { getAddress } = useGetAddress();
  const {
    handleSubmit,
    register,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isValid },
    getValues,
  } = useForm({
    defaultValues: {
      address: street,
      pinCode: zipCode,
      city: city,
      state: state,
    },
  });
  const {
    setZipValue,
    setAddressValue,
    getFeature,
    loginMemberId,
    proxyMemberId,
  } = useContext(GlobalContext);
  const ciamEnabled = getFeature?.isModernizedAPI ?? false;
  const { updateMemberDetails } = useMemberUpdate();
  const { saveSecurityQuestions } = useSaveSecurityQuestions();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [optionState, setOptionState] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isNotValidZipCode, setIsNotValidZipCode] = useState<boolean>(false);

  useEffect(() => {
    setOptionState(state);
  }, [state]);

  const closeModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsOpen(false);
    setErrorMessage('');
    closeComponent && closeComponent(e);
  };

  const onChangeZipCode = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const response = await getAddress(e.target.value);
    if (response?.errorCode) {
      errorhandler(response?.errorCode);
    } else if (!response?.data) {
      setError('pinCode', {
        type: 'no-content',
        message: '',
      });
      setIsNotValidZipCode(true);
    } else {
      clearErrors('pinCode');
      setZipValue(pinCode);
      setIsNotValidZipCode(false);
      setOptionState(response?.data?.stateCode || '');
      setValue('state', response?.data?.stateCode || '');
      setValue('city', response?.data?.city || '');
    }
  };

  // API call for updating the address
  const submitPreference = async () => {
    if (isNotValidZipCode) {
      setError('pinCode', {
        type: 'no-content',
        message: '',
      });
      return;
    }
    setLoader(true);
    const paramAddress = {
      street: getValues('address'),
      pinCode: getValues('pinCode'),
    };

    const response = await (async () => {
      if (ciamEnabled) {
        /*
         * TODO: this is currently returning a 404 for member not found
         * I'm not sure what's going on but we need to figure out why the request isn't working
         */
        return await updateMemberDetails({
          Address: {
            address1: paramAddress.street,
            city: getValues('city'),
            state: optionState,
            zip: pinCode,
          },
          memberId: loginMemberId,
        });
      } else {
        return await saveSecurityQuestions(
          proxyMemberId,
          getFeature?.AccountSettings,
          paramAddress
        );
      }
    })();

    const updatedAddress = {
      street: paramAddress?.street,
      city: getValues('city'),
      state: optionState,
      zipCode: pinCode,
      addressType: 'Home',
    };

    if (response?.errorCode) {
      if (response?.errorCode === '304') {
        setErrorMessage(t('settings.errors.addressNotUpdate') || '');
        setIsOpen(true);
      } else {
        errorhandler(response?.errorCode);
      }
    } else {
      if (setUserAddress) {
        setUserAddress([updatedAddress]);
      }
      setIsOpen(true);
    }
    setLoader(false);
  };

  return (
    <>
      {loader && <Loader />}
      <form onSubmit={handleSubmit(submitPreference)} autoComplete="off">
        <div className="d-flex row flex-lg-row flex-md-row flex-column gap-3 align-items-start align-items-lg-end align-items-md-end mb-2">
          <div className="col-md-9">
            <div className="mb-3">
              <Label
                dataTestId={'addressLabel'}
                htmlFor="profileStAddress"
                children={t('Street Address')}
                className="input-label"
              />
              <InputField
                className="form-input homeaddress-formcontrol"
                type="text"
                placeholder={''}
                id="address"
                dataTestId="profileStAddress"
                {...register('address', {
                  required: true,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setAddressValue(e.target.value);
                  },
                })}
              />
              {errors.address && (
                <div className="mt-1" data-testId={`profileStAddress`}>
                  <ErrorMessage children={t('settings.errors.streetAddress')} />
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                <Label
                  dataTestId={'cityLabel'}
                  htmlFor="profileCity"
                  children={t('City')}
                  className="input-label"
                />
                <InputField
                  className="form-input homeaddress-formcontrol"
                  type="text"
                  placeholder=""
                  id="profileCity"
                  disabled
                  readOnly
                  dataTestId="profileCity"
                  {...register('city', {})}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-3">
                <Label
                  dataTestId={'stateLabel'}
                  htmlFor="profileState"
                  children={t('State')}
                  className="input-label"
                />
                <div className="select_disabled">
                  <SelectDropdown
                    dataTestId="stateSelect"
                    className="form-select homeaddress-formcontrol"
                    id="state"
                    disabled
                    readOnly
                    value={optionState}
                    {...register('state', {})}
                  >
                    {<option value={optionState}>{optionState}</option>}
                  </SelectDropdown>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-3">
                <Label
                  dataTestId={'zipCodeLabel'}
                  htmlFor="profileZipCode"
                  children={t('settings.label.zipcode')}
                  className="input-label"
                />
                <InputField
                  className="form-input homeaddress-formcontrol"
                  type="text"
                  placeholder={''}
                  id="profileZipCode"
                  maxLength={getMaxLength.zipCode}
                  dataTestId="profileZipCode"
                  {...register('pinCode', {
                    required: true,
                    minLength: 5,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      setPincode(e.target.value);
                      if (e.target.value.length === 5) {
                        onChangeZipCode(e);
                      }
                    },
                  })}
                />

                {errors.pinCode?.type === 'required' && (
                  <div className="mt-1" data-testId={`zipCodeError`}>
                    <ErrorMessage
                      children={t('settings.errors.emptyZipcode')}
                    />
                  </div>
                )}
                {(errors.pinCode?.type === 'no-content' ||
                  errors.pinCode?.type === 'minLength') && (
                  <div className="mt-1" data-testId={`zipCodeError`}>
                    <ErrorMessage children={t('settings.errors.zipCode')} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="button_container">
            <Button
              dataTestId={'submitButton'}
              children={t('settings.label.save')}
              variant={`${
                isValid && errors.pinCode?.type !== 'no-content'
                  ? 'primary_btn btn_block_mob'
                  : 'primary_btn btn_block_mob disabled_btn'
              } `}
              type="submit"
              aria-label={t('accessibility.message.updatePhone')}
            />
            <Button
              dataTestId={'cancelButton'}
              children={t('settings.label.cancel')}
              variant={'secondary_btn btn_block_mob'}
              onClick={closeComponent}
            />
          </div>
        </div>
      </form>
      {isOpen && (
        <ModalOverlay
          isOpen
          onClose={(e: React.MouseEvent<HTMLButtonElement>) => {
            closeModal(e);
          }}
          overlayInfo="Modal information"
          className="modal-w-medium"
        >
          <div data-testid="memberIdModal">
            <Headings
              level={5}
              data-testid="loggedMessage"
              className="label_success_message"
              text={
                errorMessage !== ''
                  ? errorMessage
                  : t('settings.label.address_updated')
              }
            />
            <Button
              dataTestId={'modalSubmitButton'}
              children={t('settings.label.ok')}
              variant={'primary_btn btn_block_mob'}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                closeModal(e);
              }}
              type="submit"
            />
          </div>
        </ModalOverlay>
      )}
    </>
  );
};

export default HomeAddressDetails;
