import React, { useState } from 'react';
import { t } from 'i18next';
import { Headings, AccordionHeader } from '@sentaraui/optimahealth_web';
import {
  UserImageAvatar,
  ProfileHeaderContent,
  HomeAddressDetails,
} from 'templates';
import { UserName, AlternativeAddressDetails } from 'organisms';
import { ProfileImageProps } from './interface';
import { apiResponseSafeCheck } from 'common';

function ProfileImage({
  state,
  userAddress,
  getPhone,
  setUserAddress,
}: ProfileImageProps) {
  const [showdEdit, setShowEdit] = useState(true);

  return (
    <section className="set_my_preferences profile_image_wrapper mb-4 pb-2">
      <AccordionHeader
        dataTestId="accordionHeader"
        initialActiveIndex={null}
        title={t('settings.label.profileDetails')}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 col-12 border_wrapper">
        <div className="d-flex row px-3">
          <div className="col-lg-3 col-md-4 col-sm-12 col-12 pt-0 mt-0 pt-md-4 mt-md-3 pt-lg-4 mt-lg-3 p-0">
            <UserImageAvatar
              firstName={state?.firstName}
              lastName={state?.lastName}
            />
          </div>
          <div className="col-lg-9 col-md-8 col-sm-12 col-12 user_address_wrapper">
            <>
              <UserName
                firstName={state?.firstName ?? ''}
                lastName={state?.lastName ?? ''}
              />
            </>
            <hr className="hr_line" />
            <div className="mb-2 mb-md-3 mb-lg-3">
              <div className="row align-items-center justify-content-end">
                <div>
                  {showdEdit ? (
                    <>
                      <ProfileHeaderContent
                        header={t('settings.label.homeAddress')}
                        contentOne={userAddress[0]?.street ?? ''}
                        contentTwo={` ${
                          userAddress[0]?.city
                            ? `${userAddress[0]?.city}, `
                            : ''
                        }
                        ${userAddress[0]?.state ?? ''}
                        ${userAddress[0]?.zipCode ?? ''}`}
                        contentClassNameTwo={'margin_top_6 mb-0'}
                        contentClassNameOne={'mb-0'}
                        dataTestIdOne="userAddressStreet"
                        dataTestIdTwo="userAddress"
                        showButton={state?.canChangeContactInfo}
                        onClick={() => setShowEdit(!showdEdit)}
                      />
                    </>
                  ) : (
                    <HomeAddressDetails
                      canChangeContactInfo={
                        state?.canChangeContactInfo || false
                      }
                      userAddress={userAddress}
                      city={userAddress[0]?.city ?? ''}
                      dependents={state?.dependents || []} // Update the type of dependents prop
                      street={userAddress[0]?.street ?? ''}
                      zipCode={userAddress[0]?.zipCode ?? ''}
                      state={userAddress[0]?.state ?? ''}
                      closeComponent={() => setShowEdit(!showdEdit)}
                      setUserAddress={setUserAddress}
                    />
                  )}
                </div>
              </div>
            </div>
            {apiResponseSafeCheck(getPhone?.benefitPhone1800) ||
            apiResponseSafeCheck(getPhone?.benefitPhoneLocal) ? (
              <>
                <hr className="w-100 float-end" />
                <Headings
                  className={`redhat_bold font-sixteen fw-700 primary-color mt-2 mt-md-0 mt-lg-0 w-100 float-end`}
                  level={6}
                  text={t('settings.label.alternateAddress')}
                  dataTestId={'alternateAddress'}
                />
                <AlternativeAddressDetails
                  benefitPhone1800={getPhone?.benefitPhone1800}
                  benefitPhoneLocal={getPhone?.benefitPhoneLocal}
                />
                <hr className="mt-4 mb-0" />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProfileImage;
