import React, { useRef, useState, useEffect } from 'react';
import {
  convertStringToDate,
} from 'common';
import { format, sub } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
  DateField
} from '@sentaraui/optimahealth_web';
import { useFormContext } from 'react-hook-form';
export const ONLY_DATE_FORMAT = /[^0-9/b]/;


export const DatePicker = ({
  dobValue = '',
  fieldName = 'dob',
  required = true
}: any) => {
  const { t } = useTranslation();
  const [dateOfBirth, setDateOfBirth] = useState<string>('');

  const [maxDate, setMaxDate] = useState<Date>(new Date());
  const inputRef = useRef(null);
   const methods = useFormContext();
  const {setValue, formState: { errors }, clearErrors } = methods;
  
  //Set default date in the calendar for the Registration flow
  useEffect(() => {
    setDateOfBirth(dobValue);
  }, [dobValue]);
  
      // dob change handler
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue: string = e.target.value.replace(ONLY_DATE_FORMAT, "");
      setDateOfBirth(inputValue);
      setValue(fieldName, inputValue);
    };

    // calendar change handler
    const calendarListener = (val: any) => {
      const formattedVal = convertStringToDate(val);      
      setDateOfBirth(formattedVal);
      setValue(fieldName, formattedVal);
      clearErrors(fieldName);
    };


  useEffect(() => {
    // set max date for dob for registration flow.
    (async () => {
      const date = new Date();
      let fourteenYearsAgo = sub(date, { years: 14 });
      let maxDate = fieldName === 'from' || fieldName === 'to' ? date  : fourteenYearsAgo;
      const setDobMaxDate:any = format(maxDate, 'MM/dd/yyyy');
      setMaxDate(setDobMaxDate);
    })();
  }, [fieldName]);
   
 
  return (
    <div className="dob-field">      
      <DateField register={methods.register} error={errors.dob ? 'error-border': ''}
       maxDate={fieldName === 'from' || fieldName === 'to' ? new Date(dateOfBirth)  : maxDate} ref={inputRef}
       fieldName={fieldName}
       onChangeHandler={onChangeHandler} 
       dateOfBirth={dateOfBirth}
       required={required}
       dataTestId={"datepicker"} 
       calendarListener={calendarListener}  placeholder={
        fieldName === 'from' || fieldName === 'to'
            ? t('claims.label.selectDate') || ''
            : t('activation.placeholder.dateOfBirth') || ''
        } 
        />
      
    </div>
  );
};
