import { useEffect, useState, useContext } from 'react';
import {
  useProfileImage,
  useMemberService,
} from '@sentara/sentara-api-hooks-core';
import {
  toPascalCase,
  UrlLinks,
  signIn,
  useDeviceType,
  DeviceTypes,
  RequestStatus,
  UserDetails,
  proxyViewInfoFeatureList,
  featureToggleConstants,
  Proxy,
  backToDashboardLink,
  approvalUrl,
  Navigate,
  setLocalStorageBoolean,
  getLocalStorageBoolean,
  formsAndDocuments,
  contactUs,
  getDecryptedValue,
  deleteEncryptedValue,
  setEncryptedValue,
  apiResponseSafeCheck,
  shouldDisplayGetHealthyMenu,
} from 'common';
import {  DashboardLinks } from 'pages';
import { UserInitialsAvatar } from 'organisms';

import { GlobalContext } from 'context';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  UmbrellaIcon,
  MoneyIcon,
  StethoscopeIcon,
  AppleIcon,
  ProfileCard,
  AccordionWithNoIcon
} from '@sentaraui/optimahealth_web';

interface FeatureProps {
  [key: string]: boolean;
}

/**
 * Sides menu
 * @returns
 */
function SideMenu() {
  const deviceType = useDeviceType();
  const isDesktop = deviceType === DeviceTypes.DESKTOP;
  const { t } = useTranslation();
  const profileUrl=  window.location.pathname !== Navigate.profile
  const {
    getFeature,
    proxyData,
    isDesignatedRepresentative,
    setIsDesignatedRepresentative,
    setFeature,
    setEnableDelete,
    setUpdateProxy,
    planName,
    loginMemberId,
    serviceNumber,
    setIsBacktoDashBoard,
    setProxyMemberId,
    proxyMemberId
  } = useContext(GlobalContext);
  const navigate = useNavigate();
  const {
    benefitsUrl,
    paymentCalims,
    doctorsMedication,
    getHealthy,
    guestBenefitsUrl,
    designatedRepresentativeUrl,
    memberProfileUrl,
  }: any = UrlLinks();
 /* profile id set */
  const { getProfileImage, state } =
    useProfileImage(); /* userProfileImage API call */
  const { image: profilePhoto } = state; /* extract image from state */
  const [getPhone, setPhone] = useState<any>();
  const [planList, setPlanList] = useState('');
  const [personalInfo, setPersonalInfo] = useState<any>({});
  const [memberProxy, setMemberProxy] = useState<any>("");

  const updateFeature: boolean = getLocalStorageBoolean(Proxy?.updateFeature);
  const DesignatedDropdownLink: boolean = getLocalStorageBoolean(Proxy?.DesignatedDropdownLink);
  /* Determine getting memberlist by passing dependents and set profile name */
  useEffect(() => {
    setPlanList(planName || '');
  }, [planName]);

  /* Determine profile image api call */
  useEffect(() => {
    (async () => {
      const personalDetails = await getDecryptedValue(UserDetails.guest);
      const memberProxy = await getDecryptedValue(Proxy.memberProxy);
      setMemberProxy(memberProxy);

      const personalInfo =JSON.parse(personalDetails || '{}');
      setPersonalInfo(personalInfo);
      if (!personalInfo?.isGuest) {
          await getProfileImage();
      }
  })();
  }, []);

  useEffect(() => {
    if (apiResponseSafeCheck(serviceNumber)) {
      const getPhone = JSON.parse(serviceNumber || '{}');
      setPhone(getPhone?.data);
    }
  }, [serviceNumber]);

  /* Determine if error redirect to sign in page*/
  //User to logout when getting 401 || 440 error from API
  const erroHandling = (error: any) => {
    if (
      error === RequestStatus.status401 ||
      error === RequestStatus.status440
    ) {
      localStorage.clear();
      deleteEncryptedValue();
      window.location.href = signIn;
    }
  };
  useEffect(() => {
    if (state?.error) {
      erroHandling(state?.error);
    }
  }, [state]);  

  const memberServices = (
    <div>
      <div> {getPhone?.benefitPhone1800}</div>
      <div>{getPhone?.benefitPhoneLocal}</div>
    </div>
  );
  const handleAccordionToggleItem = (index: number) => {
    return index; // Set the initial active index here
  };

  //cancel callback function to back to previous screen
  const navigateViewDesignated = () => {
    setEnableDelete(false);
    setUpdateProxy(false);
    navigate(approvalUrl);
  };
  useEffect(()=>{
    if(memberProxy && updateFeature){
    updateProxyMemberFeature(getFeature);
    setIsDesignatedRepresentative(false);
    setLocalStorageBoolean(Proxy?.DesignatedRepresentative, false)
    setLocalStorageBoolean(Proxy?.updateFeature, false)
    }
  },[memberProxy,updateFeature]);
  const updateProxyMemberFeature = (featureObject: FeatureProps) => {
    let proxySelectedViewList: Array<string> = [];
const proxyViewFeature = { ...featureObject };
const  proxyForMemberDetails = JSON.parse(memberProxy);
    // if (proxyForMemberDetails?.Consents?.All) 
    if ((proxyForMemberDetails.Consents.MedicalClaims && proxyForMemberDetails.Consents.AccountInformation && proxyForMemberDetails.Consents.PrimaryPhysician) || proxyForMemberDetails.Consents.ClinicalInformation){
      proxyViewFeature[featureToggleConstants.memberAccount] = true;
      proxyViewFeature[featureToggleConstants.memberProfile] = true;
        proxySelectedViewList = [
            ...proxyViewInfoFeatureList.common,
            ...proxyViewInfoFeatureList.accountInformation,
            ...proxyViewInfoFeatureList.medicalClaims,
            ...proxyViewInfoFeatureList.changePCP
        ];
    } else {
        proxySelectedViewList = [...proxyViewInfoFeatureList.common];
        if (proxyForMemberDetails.Consents.AccountInformation) {
          proxyViewFeature[featureToggleConstants.memberAccount] = true;
          proxyViewFeature[featureToggleConstants.memberProfile] = true;
            proxySelectedViewList = [
                ...proxySelectedViewList,
                ...proxyViewInfoFeatureList.accountInformation,
            ];
        }
        if (proxyForMemberDetails.Consents.MedicalClaims) {
          proxyViewFeature[featureToggleConstants.memberAccount] = true;
          proxyViewFeature[featureToggleConstants.memberProfile] = true;
            proxySelectedViewList = [
                ...proxySelectedViewList,
                ...proxyViewInfoFeatureList.medicalClaims,
              
            ];
         }
         if (proxyForMemberDetails.Consents.PrimaryPhysician) {
            proxySelectedViewList = [ ...proxySelectedViewList,...proxyViewInfoFeatureList.changePCP];
        }
    }
    Object.keys(proxyViewFeature).forEach((key) => {
        if (!proxySelectedViewList.includes(key)) {
            proxyViewFeature[key] = false;
        }
    });
    setFeature({ ...proxyViewFeature });
};

  return (
    <>
      {isDesktop && (
        <div className="d-flex flex-column h-100">
          <div className="w-100">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="heading-with-bg mb-3 mt-3">
                {((window.location.pathname.indexOf(formsAndDocuments) !==-1 || window.location.pathname.indexOf(contactUs) !==-1) && isDesignatedRepresentative) &&
                  <button
                    data-testid="navLinkProfile"
                    className="btn-links link-text arrow-back mb-3 ms-1"
                    onClick={(e) => {
                      e.preventDefault();
                        navigate(Navigate.dashboard);
                    }}
                  >
                    {t('header.label.backtoDashboard')}
                  </button>
                }

           {(!(DesignatedDropdownLink && isDesignatedRepresentative)  && proxyMemberId !==''  &&
                  <button
                    data-testid="navLinkProfile"
                    className="btn-links link-text arrow-back mb-3 ms-1"
                    onClick={(e) => {
                      e.preventDefault();
                        setIsBacktoDashBoard(true);
                        navigate(Navigate.dashboard);
                        setEncryptedValue(Proxy.MemberId,"");
                        setProxyMemberId('')
                       setIsDesignatedRepresentative(true);
                       if(window.location.pathname.indexOf('dashboard') !==-1)
                       {
                       setLocalStorageBoolean(backToDashboardLink,false);
                       }
                    }}
                  >
                    {t('header.label.backtoDashboard')}
                  </button>
                )}
                <AccordionWithNoIcon
                  id="myAccount"
                  initialActiveIndex={0}
                  dataTestId="myAccount"
                  items={[
                    {
                      id: 'myOptimaAccount',
                      title: t('header.label.myOptimaAccount'),
                    },
                  ]}
                />
              </div>
              <ProfileCard
                id={loginMemberId}
                imageSrc={
                  profilePhoto?.length > 0 &&
                  'data:image/png;base64,' + profilePhoto
                }
                profileFirstLetter={
                  profilePhoto?.length <= 0 && (
                    <UserInitialsAvatar
                      firstName={personalInfo?.user?.firstName || ''}
                      lastName={personalInfo?.user?.lastName || ''}
                    />
                  )
                }
                getPhone={getPhone?.benefitPhone1800 ? memberServices : false}
                memberServices={
                  getPhone?.benefitPhone1800 ? memberServices : ''
                }
                name={`${toPascalCase(personalInfo?.user?.firstName) || ''} ${toPascalCase(personalInfo?.user?.lastName) || ''}`}
                plan={planList}
                viewDesignatedCallback={navigateViewDesignated}
                showViewDesignated={
                  proxyData.Result &&
                  proxyData.Result.length > 0 &&
                  !isDesignatedRepresentative &&
                  !personalInfo?.isGuest
                }
                data-testid="account_profile_image"
              />
              <Accordion
                onToggleItem={handleAccordionToggleItem}
                initialActiveIndex={0}
                id="acc_side_menu"
                items={[

                  getFeature?.MemberAccount &&
                  proxyMemberId !=='' && {
                    accIcon: <></>,
                    id: 'member_profile',
                    content: (
                      <>
                        {profileUrl &&
                          memberProfileUrl.length > 0 && (
                            <DashboardLinks
                              data={memberProfileUrl}
                              showContent={getFeature?.MemberAccount}
                            />
                          )}
                      </>
                    ),
                    title: t('navigation.label.memberAccount'),
                  },
                  //Benefist coverage for for member
                  getFeature?.BenefitsCoverage && ((
                    !personalInfo?.isGuest &&
                    !isDesignatedRepresentative) || proxyMemberId !=='') && {
                      accIcon: <UmbrellaIcon />,
                      id: 'benefit_coverage',
                      content: (
                        <>
                          {profileUrl&&
                            benefitsUrl.length > 0 && (
                              <DashboardLinks
                                data={benefitsUrl}
                                showContent={getFeature?.BenefitsCoverage}
                              />
                            )}
                        </>
                      ),
                      title: t('navigation.label.benefitsCoverage'),
                    },

                  //To show only enroll and benefits link in side menu for guest user
                  personalInfo?.isGuest &&
                    !isDesignatedRepresentative && proxyMemberId ==='' && {
                      accIcon: <UmbrellaIcon />,
                      id: 'benefit_coverage_guest',
                      content: (
                        <>
                          {profileUrl &&
                            guestBenefitsUrl.length > 0 && (
                              <DashboardLinks
                                data={guestBenefitsUrl}
                                showContent={getFeature?.BenefitsCoverage}
                              />
                            )}
                        </>
                      ),
                      title: t('navigation.label.benefitsCoverage'),
                    },
                     //To show only forms and documents link in side menu
                    isDesignatedRepresentative && proxyMemberId ===''  && {
                      accIcon: <UmbrellaIcon />,
                      id: 'benefit_coverage',
                      content: (
                        <>
                          {profileUrl &&
                            designatedRepresentativeUrl.length > 0 && (
                              <DashboardLinks
                                data={designatedRepresentativeUrl}
                                showContent={getFeature?.BenefitsCoverage}
                              />
                            )}
                        </>
                      ),
                      title: t('navigation.label.benefitsCoverage'),
                    },
                  //To show the PaymentsBillingAndClaims links in side menu
                  getFeature?.PaymentsBillingAndClaims && ((
                    !personalInfo?.isGuest &&
                    !isDesignatedRepresentative) || proxyMemberId !=='') && {
                      accIcon: <MoneyIcon />,
                      id: 'payment_billing',
                      content: (
                        <>
                          {profileUrl &&
                            paymentCalims.length > 0 && (
                              <DashboardLinks data={paymentCalims} />
                            )}
                        </>
                      ),
                      title: t('navigation.label.paymentsBillingsClaims'),
                    },
                 //To show the docotor and Medication link in side menu
                 getFeature?.DoctorAndMedications && ((
                  !personalInfo?.isGuest &&
                  !isDesignatedRepresentative) ||  proxyMemberId !=='') &&  {
                      accIcon: <StethoscopeIcon />,
                      id: 'doctors_medication',
                      content: (
                        <>
                          {profileUrl && <DashboardLinks data={doctorsMedication} />}
                        </>
                      ),
                      title: t('navigation.label.doctorsMedication'),
                    },
                    //To show the gethealthy link in side menu
                    shouldDisplayGetHealthyMenu(personalInfo?.isGuest, isDesignatedRepresentative, proxyMemberId, getHealthy, getFeature?.GetHealthy) && {
                      accIcon: <AppleIcon />,
                      id: 'get_healthy_guest',
                      content: (
                        <>
                          {profileUrl && <DashboardLinks data={getHealthy} />}
                        </>
                      ),
                      title: t('navigation.label.getHealthy'),
                    },
                ].filter((item) => item)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SideMenu;
