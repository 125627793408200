import React, { useState } from 'react';
import { errorhandler, PDFConverter } from 'common';
import { useMyPlanSOB, useMyPlanEOC, DocumentType } from '@sentara/sentara-api-hooks-core';
import { useTranslation } from 'react-i18next';
import { Button, Loader, FileIcon } from '@sentaraui/optimahealth_web';
interface PlanDocumentWidgetProps {
  itemClickValue: string;
  iconDataTestId: string;
  spanDataTestId: string;
  tVal: string;
}
const PlanDocumentWidget: React.FC<PlanDocumentWidgetProps> = ({
  itemClickValue,
  iconDataTestId,
  spanDataTestId,
  tVal,
}) => {
  const { t } = useTranslation();
  const { MyPlanSOBType } = useMyPlanSOB();
  const { MyPlanEOCType } = useMyPlanEOC();
  const [loader, setLoader] = useState(false);

  // Function to handle the click event when an item is clicked
  const onItemClick = async (item: any) => {
    setLoader(true);
    if (item === 'CA' || item === 'eoc' || item === 'aca') {
      handleEOCClick(item);
    } else if (
      item === 'summaryofbenefits' ||
      item === 'summaryofbenefitscoverage' ||
      item === 'benefitsummary'
    ) {
      handleSOBClick(item);
    }
  };

  // Function to handle EOC item click
  const handleEOCClick = async (item: string) => {
    const data = await MyPlanEOCType(item, '1234');
    let fileName: string = '';
    if (item === 'CA') {
      fileName =  t(`planDocuments.label.CA`);
    } else if (item === 'eoc') {
      fileName = t(`planDocuments.label.EOC`);
    } else if (item === 'aca') {
      fileName = t(`planDocuments.label.ACA`);
    }
    if (data?.errorCode !== undefined) {
      errorhandler(data?.errorCode);
    } else {
      PDFConverter(data?.data, true, fileName); // Update the type of the 'data' parameter
    }
    setLoader(false);
  };

  // Function to handle summary of benefits item click
  const handleSOBClick = async (item: string) => {
    const data = await MyPlanSOBType(DocumentType.BENEFIT_DOCUMENTS, item);
    let fileName:string = '';
    if (item === 'summaryofbenefits') {
      fileName = t(`planDocuments.label.SOB`);
    } else if (item === 'summaryofbenefitscoverage') {
      fileName = t(`planDocuments.label.USBC`);
    } else if (item === 'benefitsummary') {
      fileName = t(`planDocuments.label.BS`);
    }
    if (data?.errorCode !== undefined) {
      errorhandler(data?.errorCode);
    } else {
      PDFConverter(data?.data, false, fileName); // Provide a default value of an empty string for the data parameter
    }
    setLoader(false);
  };


  return (
  
      <div className="pdf_wrapper flex-column d-flex mb-3">
        <Button
          onClick={() => onItemClick(`${itemClickValue}`)}
          data-testid="itemClickValue"
          variant="grid-col d-grid flex-column justify-content-left align-items-center bg-transparent home-tile-link"
        >
          <strong className="d-flex" data-testid={spanDataTestId}>
            {t(`${tVal}`)}
            <span data-testid={iconDataTestId}>
              <FileIcon />
            </span>
          </strong>

          {loader && <Loader />}
        </Button>
      </div>
  );
};

export default PlanDocumentWidget;
