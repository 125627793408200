import { ECacheType, EDurationType } from '@sentara/sentara-api-hooks-core';

export const getCache = (
  duration: number = 30,
  durationType: EDurationType = EDurationType?.minutes,
  type: ECacheType = ECacheType?.retriveIfExists,
  secretPass: string = process.env.REACT_APP_SECRET_PASS ?? ''
) => {
  return { duration, durationType, type, secretPass };
};
