import { Paragraph } from '@sentaraui/optimahealth_web';
import {ProfileInformations} from "templates"

const UserInitialsAvatar = ({ firstName = '', lastName = '' }: ProfileInformations) => {
  /* Detemine user first letter */
  const getUserFirstLetter = () => {
    return `${firstName[0]}${lastName[0]}`.toUpperCase() || '';
 };
  
  return (
      <div
        data-testid="firstLetter"
        className="d-flex rounded-circle mx-auto justify-content-center align-items-center profile_image profile_letter"
      >
        <Paragraph className="m-0">{getUserFirstLetter()}</Paragraph>
      </div>
  );
}

export default UserInitialsAvatar;
