import React from 'react';
import {
  ErrorMessage,
  Headings,
  InputField,
  Label,
  PrimaryButton,
  SecondaryButton,
} from '@sentaraui/optimahealth_web';
import { t } from 'i18next';
import ResetPassword from './ResetPassword';
import { useSecurityQuestion } from './useSecurityQuestion';

const SecurityQuestion = () => {
  const {
    handleCancel,
    handleChange,
    onSubmit,
    securityAnswer,
    securityAnswerError,
    securityAnswerResponse,
    securityQuestion,
    securityAnswerEncodeToken,
    securityAnswerDecodeToken,
    showSecurityQuestion,
    securityQuestionSuccess,
    tokenExpiry,
  } = useSecurityQuestion();

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-lg-5 col-md-6 col-sm-12 col-12">
          {tokenExpiry && (
            <div className="text-center mb-5">
              <Headings
                level={1}
                text={t('resetPassword.label.header')}
                dataTestId="forgotUsernameHeading"
                className="mb-5"
              />
              <Label
                className="pb-4"
                dataTestId={'securityQuestionsLabel'}
                htmlFor="securityQuestionsLabel"
                children={t('forgotPassword.message.showTokenError')}
              />
              <PrimaryButton
                data-testid="returnToSignIn"
                className={'primary_btn btn_block_mob'}
                children={t('forgotPassword.label.returnLogin')}
                onClick={handleCancel}
              />
            </div>
          )}
          {!tokenExpiry && !securityQuestionSuccess && (
            <>
              <Headings
                level={1}
                text={t('userprofile.label.securityquestions')}
                dataTestId="forgotUsernameHeading"
                className="mb-5"
              />
              <div className="mb-4 singin-input">
                <div>
                  <Label
                    className="pb-1"
                    dataTestId={'securityQuestionsLabel'}
                    htmlFor="securityQuestionsLabel"
                    children={securityQuestion?.KBAQuestion}
                  />
                </div>
                <InputField
                  dataTestId="securityQuestionInput"
                  value={securityAnswer}
                  placeholder={t('forgetUser.message.secQuesAnswer')}
                  maxLength={50}
                  showIcon={true}
                  className={
                    showSecurityQuestion
                      ? 'error-border psw-input'
                      : 'psw-input'
                  }
                  id="securityQuestion"
                  type={'password'}
                  onChange={handleChange}
                  name="securityQuestion"
                  aria-describedby="securityQuestion_error"
                />
                {showSecurityQuestion && (
                  <div className="mt-1" data-testid="securityQuestion_error">
                    <ErrorMessage>{securityAnswerError}</ErrorMessage>
                  </div>
                )}
              </div>
              <hr className="mb-4" />
              <div className="d-flex flex-column flex-lg-row flex-md-row gap-3 mb-4">
                <PrimaryButton
                  data-testid="submitButton"
                  className={
                    securityAnswer?.length >= 3
                      ? 'primary_btn btn_block_mob'
                      : 'primary_btn btn_block_mob disabled_btn'
                  }
                  children={t('activation.label.submit')}
                  onClick={onSubmit}
                  disabled={showSecurityQuestion}
                />
                <SecondaryButton
                  data-testid="cancelButton"
                  onClick={handleCancel}
                  type="button"
                  variant={'secondary_btn btn_block_mob'}
                  children={t('activation.label.cancel')}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {!tokenExpiry && securityQuestionSuccess && securityAnswerEncodeToken ? (
        <ResetPassword
          params={securityAnswerDecodeToken}
          authId={securityAnswerResponse?.authId}
        />
      ) : null}
    </>
  );
};

export default SecurityQuestion;
