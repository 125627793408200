import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Headings,
  ModalOverlay,
  Paragraph,
  TextNotificationIcon,
  PaperLessMailIcon,
} from '@sentaraui/optimahealth_web';
import { Navigate } from 'common';

interface PaperlessPreferencePopupProps {
  smsConsent: boolean;
  emailConsent: boolean;
  closeModal: () => void;
}

function PaperlessPreferencePopup({
  smsConsent,
  emailConsent,
  closeModal,
}: PaperlessPreferencePopupProps) {
  // Initialize translation hook
  const { t } = useTranslation();

  // Initialize navigation hook
  const navigate = useNavigate();

  return (
    <>
      <ModalOverlay
        data-testid={'preferencesInformationId'}
        isOpen
        onClose={closeModal}
        overlayInfo="preferences information"
      >
        <Headings level={2} text={t('preferences.header.title')} />
        <Paragraph className="mb-4">
          {smsConsent && emailConsent
            ? t('preferences.header.paperlessAndText')
            : smsConsent
            ? t('preferences.header.textNotifications')
            : t('preferences.header.paperlessCommunications')}
        </Paragraph>

        <div className="flex-lg-row flex-md-row flex-column gap-lg-3 gap-md-3 gap-4 mb-2">
          <div className="d-flex flex-lg-row flex-md-row flex-column gap-lg-3 gap-md-3 gap-4 mb-4 set_my_preferences">
            {emailConsent && (
              <div className="preferences_border w-100">
                <div className="d-flex gap-2 align-items-center m-1">
                    <PaperLessMailIcon />
                  <Headings
                    className="mb-1"
                    level={2}
                    text={t('preferences.label.paperlessCommunications')}
                  />
                </div>
                <Paragraph>
                  {t('preferences.content.paperlessCommunications')}
                </Paragraph>
              </div>
            )}
            {smsConsent && (
              <div className="preferences_border w-100">
                <div className="d-flex gap-2 align-items-center m-1">
                    <TextNotificationIcon />
                  <Headings
                    className="mb-1"
                    level={2}
                    text={t('preferences.label.textNotifications')}
                  />
                </div>
                <Paragraph>
                  {t('preferences.content.textNotifications')}
                </Paragraph>
              </div>
            )}
          </div>
          <div className="d-flex flex-column flex-lg-row flex-md-row flex-sm-row gap-3 mb-3">
            <Button
              className="btn w-100"
              data-testid={'updatePreferencesButton'}
              children={t('preferences.label.updatePreferences')}
              variant={'primary_btn'}
              type="submit"
              onClick={(e: any) => {
                e.preventDefault();
                closeModal();
                navigate(Navigate.profile);
              }}
            />
            <Button
              className="btn w-100"
              data-testid={'skipButton'}
              children={t('preferences.label.skipNow')}
              variant={'secondary_btn'}
              onClick={() => {
                closeModal();
              }}
            />
          </div>
        </div>
      </ModalOverlay>
    </>
  );
}

export default PaperlessPreferencePopup;
