
import { useTranslation } from 'react-i18next';
import {
  Headings
} from '@sentaraui/optimahealth_web';
interface EmailPromptProps {
  setIsOpen: (response: any) => void;
}

const EmailPrompt = ({ setIsOpen }: EmailPromptProps) => {
  

  return (
          <Headings text={"Go Paperless Popup"} level={3} />
  );
}

export default EmailPrompt;