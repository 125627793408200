import { t } from 'i18next';
import { convertToDate } from 'common';
import { ContactInfo,Paragraph } from '@sentaraui/optimahealth_web';
export interface IphoneNumber {
  benefitPhone: string;
  benefitPhoneLocal: string;
}

function HeaderContent({ benefitPhone, benefitPhoneLocal }: IphoneNumber) {
  let startDate;
  let endDate;
  const date = new Date();

  const selectedOption = t('localStorage.selectedOption');
  const yearData = localStorage.getItem(selectedOption);
  if (Number(yearData) === date.getFullYear()) {
    startDate = convertToDate(new Date(date.getFullYear(), 0, 1));
    endDate = convertToDate(date);
  } else {
    startDate = convertToDate(new Date(date.getFullYear() - 1, 0, 1));
    endDate = convertToDate(new Date(date.getFullYear(), 0, 0));
  }

  return (
    <>
      <Paragraph data-testid="taxYearSummary" className="header_content">
        {t('claimsDetails.message.billInstruction1')}{' '}
        {t('claimsDetails.message.billInstruction2')} {startDate}{' '}
        {t('claimsDetails.message.billInstruction4')} {endDate}.
      </Paragraph>
      <Paragraph className="mb-4" data-testid="billInstruction">
        {t('claimsDetails.message.billInstruction3')}{' '}
        <ContactInfo
          phoneData={{
            phone1800: benefitPhone,
            phoneLocal: benefitPhoneLocal,
          }}
        />
      </Paragraph>
    </>
  );
}

export default HeaderContent;
