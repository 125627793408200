import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './i18n';
import { GlobalProvider } from 'context';
import { BrowserRouter } from 'react-router-dom';

import {
  UserDetails,
  Proxy,
  getDecryptedValue,
} from 'common';

const startApplication = async () => {
  if (process.env.NODE_ENV === 'production') {
    console.warn = function () {};
    console.error = function () {};
    console.log = function () {};
  }

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  const MemberId = (await getDecryptedValue(Proxy.MemberId)) || '';
  let header = {
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCRIPTION_KEY
  };
  if (MemberId !== '') {
    let data = {
      Proxyfor: MemberId,
    };
    header = Object.assign(header, data);
  }
  const authToken = await getDecryptedValue(UserDetails.authToken);
  const personalDetails = await getDecryptedValue(UserDetails.guest);
  const personalInfo =JSON.parse(personalDetails || '{}');
  const xAuthToken = personalInfo?.xAuthToken || '';
  const xSentaraTokenID = personalInfo?.xSentaraTokenID || '';
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <GlobalProvider>
            <App header={header} authToken={authToken} xAuthToken={xAuthToken} xSentaraTokenID={xSentaraTokenID}/>
        </GlobalProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
};

startApplication();
