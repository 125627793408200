import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HeaderLogo,
  ContactUsLink,
  SearchLink,
  HamburgerMenuLink,
  FooterDisclaimer,
} from 'organisms';
import { Stepper, Link } from '@sentaraui/optimahealth_web';
import { useSelectPlan } from '@sentara/sentara-api-hooks-core';
import RegistrationSteps from './RegistrationSteps';
import { GUEST_REGISTRATION_STEP_ONE, REGISTRATION_STEP_ONE, UserDetails } from 'common';

function Form() {
  document.cookie = UserDetails.optimaPersonCookie + '=';
  document.cookie = UserDetails.optimaRoleCookie + '=';
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const { SelectPlanType } = useSelectPlan();
  const [item, setItems] = useState<string[]>([]);
  const guest: boolean = !(params.get('guest') === null || params.get('guest') === 'false');
  const switchguest = guest ? GUEST_REGISTRATION_STEP_ONE : REGISTRATION_STEP_ONE;
  const { t } = useTranslation();
  const [page, setPage] = useState(switchguest);
  const [plantypes, setPlantypes] = useState<string>('');
  const [formData, setFormData] = useState<any>({
   
  });

  const generateFormTitles = (numSteps: number) => {
    return Array.from({ length: numSteps }, (_, index) => `Step ${guest ? index :index + 1}`);
  };
  const FormTitles = generateFormTitles(5);

  useEffect(() => {
    (async () => {
      const response: any = await SelectPlanType();
      setItems(response);
    })();
    document.title = t('pageTitle.label.Registration');
  }, [SelectPlanType]);

  const wordNumbers = ['One', 'Two', 'Three', 'Four', 'Five'];

  const generateStepperData = (currentPage: number) => {
    const labelsToUse = guest ? FormTitles.slice(1) : FormTitles;
    const indexValue = guest ? 1 : 0;
    return labelsToUse
      .filter((label) => label)
      .map((label, index) => ({
        label,
        number: index + 1,
        testId: `stepperStep${wordNumbers[index + 1]}`,
        active: currentPage === index + indexValue,
        selected: currentPage === index + indexValue + 1,
      }));
  };

  const stepperData = generateStepperData(page);

  return (
    <div className="d-flex flex-column h-100">
      {/* Skip to Main Content link */}
      <Link to="#main-content" className="skip-link">
        {t('accessibility.message.skipToMainContent')}
      </Link>
      <div>
        <header>
          <div className="">
            <div className="header-wrapper">
              <nav className="header-inner">
                <div>
                  <HeaderLogo />
                </div>
                <div>
                  <div className="d-flex flex-no-wrap align-items-center">
                    <div className="d-none d-xl-block d-lg-block d-md-none ms-4">
                      <ContactUsLink showIcon={false} />
                    </div>
                    <div className="d-lg-none d-md-block d-sm-block">
                      <SearchLink />
                    </div>
                    <div className="d-lg-none d-md-block d-sm-block">
                      <HamburgerMenuLink showLogout={false} />
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          <hr className="border-w-2 mt-0" />
        </header>
        <div id="main-content" className="container-fluid" role="main">
          <div className="row justify-content-center">
            <div className="col-xl-11 col-lg-11 col-md-12 col-sm-12 col-12">
              <div className="row justify-content-center">
                <div className="pt-3 pb-5">
                  <Stepper registerSteps={stepperData} />
                </div>
              </div>

              <div className="page_container">
                {
                  <RegistrationSteps
                    page={page}
                    setPage={setPage}
                    formData={formData}
                    setFormData={setFormData}
                    setPlantypes={setPlantypes}
                    plantypes={plantypes}
                    item={item}
                    guest={guest}
                    t={t}
                    FormTitles={FormTitles}
                  />
                }
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
      <footer className="mt-auto">
        <FooterDisclaimer />
      </footer>
    </div>
  );
}

export default Form;
