import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enSentara from './common/locales/en/sentara.json';
import enAvMed from './common/locales/en/avmed.json';
import merge from 'deepmerge';

interface Translation {
  [key: string]: string | Translation;
}

export enum Brand {
  SENTARA = 'sentara',
  AVMED = 'avMed',
}

const enSentaraTyped: Translation = enSentara;
const enAvMedTyped: Translation = enAvMed;

// Get the correct resource based on BRAND
function getEnResource() {
  if (process.env.BRAND === Brand.AVMED) {
    return merge(enSentaraTyped, enAvMedTyped);
  }
  return enSentaraTyped;
}

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: getEnResource(),
      },
    },
  });
export default i18n;
